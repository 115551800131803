<div class="wl-task-item-selector-body">
    <app-wl-strict-multi-select-dropdown *ngIf="isBetType || isWinType || isRedeemType"
                                         class="wl-crm-task-row"
                                         [strategy]="betTypeStrategy"
                                         title="Тип ставки "
                                         dropDownPlaceHolder="Выберите тип ставки"
                                         (userSelect)="change.next()"
                                         [multiSelectControl]="taskItem.betTypes"
                                         [shouldShowErrorList]="true"
    ></app-wl-strict-multi-select-dropdown>
    <app-wl-strict-input-number *ngIf="isByCountType && !isCollectInExpress && !isDepositsType"
                                class="wl-crm-task-row"
                                title="Количество ставок"
                                placeholder=""
                                min="1"
                                [control]="taskItem.betCount">
    </app-wl-strict-input-number>
    <app-wl-battle-pass-range-condition *ngIf="isShowSeveralBetsSumRange"
                                        class="wl-crm-task-row"
                                        [title]="'Общая сумма ставок'"
                                        [allowedConditionalType]="moreOrEqualStrongConditionalType"
                                        (change)="change.emit()"
                                        minValue="1"
                                        [viewModel]="taskItem.severalBetsSumRange">
    </app-wl-battle-pass-range-condition>
    <app-wl-battle-pass-range-condition *ngIf="isWinType && isBySumType && !isCollectInExpress"
                                        class="wl-crm-task-row"
                                        [title]="'Общая сумма выигрыша'"
                                        [allowedConditionalType]="moreOrEqualStrongConditionalType"
                                        (change)="change.emit()"
                                        minValue="1"
                                        [viewModel]="taskItem.totalWinAmountRange">
    </app-wl-battle-pass-range-condition>
    <app-wl-battle-pass-range-condition *ngIf="isBetExpressType && !isCollectInExpress"
                                        class="wl-crm-task-row"
                                        [allowedConditionalType]="singleValuesConditionalTypes"
                                        [title]="'Событий в экспрессе'"
                                        (change)="change.emit()"
                                        minValue="1"
                                        [viewModel]="taskItem.eventCountExpressRange">
    </app-wl-battle-pass-range-condition>
    <app-wl-battle-pass-range-condition *ngIf="isBetExpressOrAnyBetType"
                                        class="wl-crm-task-row"
                                        [allowedConditionalType]="singleValuesConditionalTypes"
                                        [title]="'Коэффициент линии'"
                                        (change)="change.emit()"
                                        [minValue]="1.01"
                                        [step]="0.01"
                                        [viewModel]="taskItem.lineRateRange">
    </app-wl-battle-pass-range-condition>
    <app-wl-battle-pass-range-condition *ngIf="(isBetType || isWinType || isRedeemType) && !isCollectInExpress"
                                        class="wl-crm-task-row"
                                        [allowedConditionalType]="singleValuesConditionalTypes"
                                        [title]="'Коэффициент ставки'"
                                        [minValue]="1.01"
                                        [step]="0.01"
                                        (change)="change.emit()"
                                        [viewModel]="taskItem.betRateRange">
    </app-wl-battle-pass-range-condition>
    <app-wl-battle-pass-range-condition *ngIf="isByCountType && !isCollectInExpress && !isDepositsType"
                                        class="wl-crm-task-row"
                                        [title]="'Сумма ставки'"
                                        (change)="change.emit()"
                                        minValue="1"
                                        [viewModel]="taskItem.betSumRange">
    </app-wl-battle-pass-range-condition>
    <app-wl-dropdown *ngIf="isBetType || isWinType || isRedeemType"
                     class="wl-crm-task-row"
                     [strategy]="sportTypeStrategy"
                     [options]="{placeholder: 'Выберите вид спорта',title:'Вид спорта '}"
                     [defaultValue]="setInitSportType()"
                     (userSelect)="change.next()"
                     [control]="taskItem.sportType">
    </app-wl-dropdown>
    <app-wl-dropdown *ngIf="isBetType || isWinType || isRedeemType"
                     class="wl-crm-task-row"
                     [strategy]="betPeriodStrategy"
                     [options]="{ placeholder: 'Выберите период ставки', title: 'Период ставки ' }"
                     (userSelect)="change.next()"
                     [control]="taskItem.betPeriod"
    >
    </app-wl-dropdown>
    <ng-container *ngIf="isBetType || isWinType || isRedeemType">
        <app-wl-dropdown class="wl-crm-task-row"
                         [control]="taskItem.isExcludedSportType"
                         (userSelect)="change.next()"
                         [options]="{ placeholder: 'Не указано', title: 'Виды спорта' }"
                         [strategy]="filterStrategy"
        ></app-wl-dropdown>
        <app-wl-strict-multi-select-dropdown
                dropDownPlaceHolder="Выберите виды спорта"
                class="wl-crm-task-row"
                (userSelect)="change.next()"
                [strategy]="sportTypeStrategy"
                [maxElementsCount]="maxGameItems"
                [multiSelectControl]="taskItem.sportTypes"
                [shouldShowErrorList]="true"
        ></app-wl-strict-multi-select-dropdown>
    </ng-container>
    <app-wl-strict-multi-select-dropdown *ngIf="isBetType || isWinType || isRedeemType"
                                         class="wl-crm-task-row"
                                         title="Список платформ ставок"
                                         dropDownPlaceHolder="Выберите платформу"
                                         (userSelect)="change.next()"
                                         [strategy]="betPlatformStrategy"
                                         [multiSelectControl]="taskItem.betPlatforms"
    >
    </app-wl-strict-multi-select-dropdown>
    <app-wl-strict-input-number *ngIf="isBetType || isWinType || isRedeemType"
                                class="wl-crm-task-row"
                                title="Чемпионат"
                                placeholder=""
                                min="1"
                                [control]="taskItem.championshipId">
    </app-wl-strict-input-number>
    <ng-container *ngIf="isBetType || isWinType || isRedeemType">
        <app-wl-dropdown [control]="taskItem.isExcludedGames"
                         class="wl-crm-task-row"
                         (userSelect)="change.next()"
                         [options]="{ placeholder: 'Не указано', title: 'Дисциплина' }"
                         [strategy]="filterStrategy"
        ></app-wl-dropdown>
        <app-wl-strict-multi-select-dropdown dropDownPlaceHolder="Выберите дисциплину"
                                             class="wl-crm-task-row"
                                             (userSelect)="change.next()"
                                             [strategy]="gamesStrategy"
                                             [maxElementsCount]="maxGameItems"
                                             [multiSelectControl]="taskItem.games"
                                             [shouldShowErrorList]="true"
        ></app-wl-strict-multi-select-dropdown>
        <app-wl-strict-input-boolean *ngIf="isBetType || isWinType || isRedeemType"
                                     label="Учитывать фрибетные ставки"
                                     (userSelect)="change.next()"
                                     [control]="taskItem.countFreeBets"
                                     class="wl-crm-task-row"
        ></app-wl-strict-input-boolean>
        <app-wl-strict-input-boolean *ngIf="isBetType"
                                     label="Учитывать в прогрессе не рассчитанные ставки"
                                     (userSelect)="change.next()"
                                     [control]="taskItem.countBetsWithoutResult"
                                     class="wl-crm-task-row"
        ></app-wl-strict-input-boolean>
    </ng-container>
    <app-wl-battle-pass-range-condition *ngIf="isWinType && isByCountType && !isCollectInExpress"
                                        class="wl-crm-task-row"
                                        [allowedConditionalType]="singleValuesConditionalTypes"
                                        [title]="'Сумма выигрыша ставки'"
                                        (change)="change.emit()"
                                        minValue="1"
                                        [viewModel]="taskItem.betWinAmountRange">
    </app-wl-battle-pass-range-condition>
    <div class="wl-crm-task-row" *ngIf="isBetType || isWinType || isRedeemType">
        <div class="market-header-block">
            <span class="wl-field-title">Маркеты:</span>
            <button class="wl-btn-form-active" type="button" (click)="addMarket()">Добавить маркет</button>
        </div>
        <div class="market-item" *ngFor="let market of taskItem.activeMarkets.strictValue; let i = index">
            <div class="market-item-name">
                <span class="wl-field-text">{{ market.market.strictValue.id }} - {{ market.market.strictValue.name }}</span>
                <a class="wl-test-exports-list-link a-button" [routerLink]="" (click)="editMarket(i)">Редактировать</a>
                <a class="wl-test-exports-list-link a-button" [routerLink]="" (click)="deleteMarket(i)">Удалить</a>
            </div>
            <div class="market-item-fts">
                <span *ngIf="market.ftsParameterA.strictValue" class="wl-field-text">[a]:{{ market.ftsParameterA.strictValue }}</span>
                <span *ngIf="market.ftsParameterB.strictValue" class="wl-field-text">[b]:{{ market.ftsParameterB.strictValue }}</span>
                <span *ngIf="market.ftsParameterC.strictValue" class="wl-field-text">[c]:{{ market.ftsParameterC.strictValue }}</span>
            </div>
        </div>
    </div>
    <app-wl-strict-input-number *ngIf="isDepositsType && isByCountType"
                                class="wl-crm-task-row"
                                title="Количество депозитов"
                                placeholder=""
                                min="1"
                                [control]="taskItem.depositsCount">
    </app-wl-strict-input-number>
    <app-wl-battle-pass-range-condition *ngIf="isDepositsType && isByCountType"
                                        class="wl-crm-task-row"
                                        [allowedConditionalType]="singleValuesConditionalTypes"
                                        [title]="'Сумма депозита'"
                                        (change)="change.emit()"
                                        minValue="1"
                                        [viewModel]="taskItem.depositsSumRange">
    </app-wl-battle-pass-range-condition>
    <app-wl-battle-pass-range-condition *ngIf="isDepositsType && isBySumType"
                                        class="wl-crm-task-row"
                                        [title]="'Общая сумма депозитов'"
                                        (change)="change.emit()"
                                        minValue="1"
                                        [allowedConditionalType]="moreOrEqualStrongConditionalType"
                                        [viewModel]="taskItem.totalDepositsAmountRange">
    </app-wl-battle-pass-range-condition>
    <hr>
    <div class="wl-button-row">
        <button class="wl-btn-form-active" type="button" [disabled]="isAddTaskDisabled" (click)="addTask()">Добавить задание</button>
        <div *ngIf="isDeleteButtonActive">
            <button class="wl-btn-form-inactive wl-button-position" type="button" (click)="delete()">Удалить</button>
        </div>
    </div>
    <hr/>
    <div *ngIf="isTaskExist" class="wl-task-item-selector-body">Id подзадания: {{ taskItem.id.strictValue }}</div>
</div>
