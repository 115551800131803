import { AbstractModelValidator, AddErrorsToControlMode, isNullOrUndefined, StrictError, StrictFormControl, ValidationLevel } from '@koddington/ga-common';
import { WlGameLootBoxLiteModel } from 'src/app/modules/autogen/BattlePass';
import { WlLevelGameLootBoxViewModel } from '../../../../../models/wl-level-game-loot-box-view-model';
import { WlBattlePassGameLootBoxVariation } from './helpers/wl-battle-pass-game-loot-box-variation';
import { WlBattlePassLootBoxesConsts } from './helpers/wl-battle-pass-loot-boxes-consts';

export class WlBattlePassLevelGameLootBoxValidator extends AbstractModelValidator<WlLevelGameLootBoxViewModel> {
    private _groupedGameLootBoxes = new Map<string, StrictFormControl<WlGameLootBoxLiteModel>[]>();

    constructor() {
        super();

        this.ruleForControl(u => u.lootBox)
            .required('Игровой лутбокс обязателен')
            .customRule((field, model) => {
                if (isNullOrUndefined(model?.completedTaskType?.strictValue) || isNullOrUndefined(model?.lootBox?.strictValue)) {
                    return null;
                }
                const key = new WlBattlePassGameLootBoxVariation(field?.gameType, model?.completedTaskType?.strictValue).getDescription();

                const lootDuplicates = this._groupedGameLootBoxes
                                           .get(key)
                                           .filter(u => model.lootBox.strictValue !== u.strictValue)
                                           .map(u => u.strictValue.id);

                return lootDuplicates.length === 0
                    ? null
                    : new StrictError('gameLootBoxDuplicationError', `Указанная связка Игра + Тип используется лутбоксами: ${lootDuplicates.join(', ')}`);
            })
            .customRule(field => {
                if (isNullOrUndefined(field?.gameType)) {
                    return new StrictError('gameLootBoxTypeError', 'Не указан тип игры в лутбоксе');
                }

                return WlBattlePassLootBoxesConsts.availableGameLootBoxTypes.includes(field.gameType)
                    ? null
                    : new StrictError('gameLootBoxTypeError', 'Указанный тип игры недоступен для уровней');
            });

        this.ruleForControl(u => u.completedTaskType)
            .required('Тип задания обязателен')
            .customRule(field => WlBattlePassLootBoxesConsts.availableCompleteTaskTypes.includes(field)
                ? null
                : new StrictError('gameLootBoxTaskTypeError', 'Указанный тип задания недоступен для уровней'));
    }

    public validateCustom(
        model: WlLevelGameLootBoxViewModel[],
        knownLootBoxesPairs: Map<string, StrictFormControl<WlGameLootBoxLiteModel>[]>,
        validationLevel?: ValidationLevel,
        addErrorsToControls?: AddErrorsToControlMode): StrictError[] {
        this._groupedGameLootBoxes = knownLootBoxesPairs;

        return super.validateArray(model, validationLevel, addErrorsToControls);
    }
}
