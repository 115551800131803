import { StrictFormControl } from '@koddington/ga-common';
import { BetPeriods, WlCrmBetPlatforms, WlBetTypes, WlFiltersTypes, WlGamesModel, WlSportTypeModel, WlBetTypesFlags } from '../../autogen/BattlePass';
import { WlRangeConditionViewModel } from './wl-range-condition-view-model';
import { WlMarketsParameterViewModel } from './wl-markets-parameter-view-model';

export class WlCrmTaskViewModel {
    public id: StrictFormControl<number> = new StrictFormControl<number>(null);
    public betTypes: StrictFormControl<WlBetTypesFlags[]> = new StrictFormControl<WlBetTypesFlags[]>([]);
    public betCount: StrictFormControl<number> = new StrictFormControl<number>(null);
    public sportType: StrictFormControl<WlSportTypeModel> = new StrictFormControl<WlSportTypeModel>(null);
    public eventCountExpressRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public betRateRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public lineRateRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public betSumRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public activeMarkets: StrictFormControl<WlMarketsParameterViewModel[]> = new StrictFormControl<WlMarketsParameterViewModel[]>([]);
    public betPeriod: StrictFormControl<BetPeriods> = new StrictFormControl<BetPeriods>(null);
    public betPlatforms: StrictFormControl<WlCrmBetPlatforms[]> = new StrictFormControl<WlCrmBetPlatforms[]>([]);
    public championshipId: StrictFormControl<number> = new StrictFormControl<number>(null);
    public isExcludedGames: StrictFormControl<WlFiltersTypes> = new StrictFormControl<WlFiltersTypes>(null);
    public games: StrictFormControl<WlGamesModel[]> = new StrictFormControl<WlGamesModel[]>([]);
    public totalWinAmountRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public betWinAmountRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public depositsCount: StrictFormControl<number> = new StrictFormControl<number>(null);
    public depositsSumRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public totalDepositsAmountRange: StrictFormControl<WlRangeConditionViewModel> = new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public severalBetsSumRange: StrictFormControl<WlRangeConditionViewModel> =
        new StrictFormControl<WlRangeConditionViewModel>(new WlRangeConditionViewModel());
    public isExcludedSportType: StrictFormControl<WlFiltersTypes> = new StrictFormControl<WlFiltersTypes>(null);
    public sportTypes: StrictFormControl<WlSportTypeModel[]> = new StrictFormControl<WlSportTypeModel[]>([]);
    public countFreeBets: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public countBetsWithoutResult: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
}
