import {Component, OnInit} from '@angular/core';
import { GaSearchManagementStateService } from '@koddington/ga-common';
import {formatDateTime, GaPagedResult, GaPagingForm, GaTableData} from '@koddington/ga-common';
import {Router} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {DailyTasksDaysListSearchViewModel} from './models/daily-tasks-days-list-search-view-model';
import {
    DailyTasksDaysService,
    WlDtPromotionDayListModel,
    WlPromotionsDaysSearchForm,
    WlDailyTaskStatesExtensions,
    WlPromoTypesExtensions
} from '../../../autogen/DailyTasks';
import { booleanToString } from '../../../shared/extensions/convert-extensions';
import { DailyTaskStateStrategy } from '../../strategies/daily-task-state-strategy.service';
import { WlPromoTypesDropdownStrategy } from '../../strategies/wl-promo-types.dropdown-strategy';


@UntilDestroy()
@Component({
    selector: 'app-daily-tasks-days-list',
    templateUrl: './daily-tasks-days-list.component.html',
    providers: [GaSearchManagementStateService],
})
export class DailyTasksDaysListComponent implements OnInit {
    public result: GaPagedResult<WlDtPromotionDayListModel>;
    public tableData: GaTableData<WlDtPromotionDayListModel>;
    public viewSearchModel: DailyTasksDaysListSearchViewModel = new DailyTasksDaysListSearchViewModel();

    constructor(
        private readonly _httpService: DailyTasksDaysService,
        private readonly _searchManagementState: GaSearchManagementStateService<GaPagedResult<WlDtPromotionDayListModel>>,
        private readonly _router: Router,
        protected readonly promoTypesStrategy: WlPromoTypesDropdownStrategy,
        protected readonly dailyTaskStatesStrategy: DailyTaskStateStrategy,
    ) {
    }

    ngOnInit() {
        this._searchManagementState.init((form) => this._httpService.list(form as WlPromotionsDaysSearchForm), this.viewSearchModel);
        this._searchManagementState.searchState.pipe(untilDestroyed(this)).subscribe((result) => {
            this.result = result;
            this._searchManagementState.loadingStatus = false;
            this.mapToTable();
        });
    }

    public pageFiltered(): void {
        this._searchManagementState.nextSearch(null, true);
    }

    public add(): void {
        this._router.navigate(['/menu/dailyTasks/days/create']);
    }

    public pageChanged(page: GaPagingForm): void {
        this._searchManagementState.nextSearch(page);
    }

    private mapToTable(): void {
        this.tableData = new GaTableData<WlDtPromotionDayListModel>()
            .addRouterLinkColumn(
                u => u.id,
                u => ['/menu/dailyTasks/days/update', u.id],
                {title: 'Код', widthSize: 80}
            )
            .addSimpleColumn((u) => u.name, {title: 'Название дня', widthSize: 200})
            .addSimpleColumn((u) => WlPromoTypesExtensions.format(u.promoType) , {title: 'Тип дня', widthSize: 130})
            .addSimpleColumn(u => formatDateTime(u.startDate), {title: 'Дата старта', widthSize: 140})
            .addSimpleColumn(u => formatDateTime(u.endDate), {title: 'Дата окончания', widthSize: 145})
            .addSimpleColumn(u => booleanToString(u.isOffPlayDay), {title: 'Неигровой', widthSize: 100})
            .addSimpleColumn(u => WlDailyTaskStatesExtensions.format(u.state), {title: 'Состояние', widthSize: 130})
            .addSimpleColumn(u => formatDateTime(u.entityActors.createdDate), {title: 'Дата создания', widthSize: 120})
            .addSimpleColumn(u => u.entityActors.creatorAccountLogin, {title: 'Кем создан', widthSize: 140})
            .addSimpleColumn(u => formatDateTime(u.entityActors.modifiedDate), {title: 'Дата изменения', widthSize: 120})
            .addSimpleColumn(u => u.entityActors.editorAccountLogin, {title: 'Кем изменён', widthSize: 140})

            .setData(this.result.results);
    }

    get loading(): boolean {
        return this._searchManagementState.loadingStatus;
    }
}



