<app-wl-page-header header="Профиль пользователя"></app-wl-page-header>
<div class="wl-search-row">
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-right-8"
            isSearch="true"
            [control]="viewModel.userId"
            [placeholder]="'Id пользователя'"
            [min]="0"
            (searchMethod)="search()"
    ></app-wl-strict-input-number>
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-right-8"
            isSearch="true"
            [control]="viewModel.promoId"
            [placeholder]="'Id акции'"
            [min]="0"
            (searchMethod)="search()"
    ></app-wl-strict-input-number>
    <button class="wl-search-btn wl-margin-right-8" (click)="search()" [disabled]="isSearchForbidden">Поиск</button>
</div>
<div class="wl-userinfo-container" *ngIf="result && !loading">
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Id пользователя: </span>
        <span class="wl-userinfo-field-value">
            {{ result.userId }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Текущее количество доступных попыток: </span>
        <span class="wl-userinfo-field-value">
            {{ result.attemptsAvailable }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Количество полученных дополнительных попыток: </span>
        <span class="wl-userinfo-field-value">
            {{ result.dropAttemptsCount }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Текущий дроп: </span>
        <span class="wl-userinfo-field-value">
            {{ result.promoDropProgress }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Id акции: </span>
        <span class="wl-userinfo-field-value">
            <a [routerLink]="['/menu/dailyTasks/promotions/update', result.promoInfo?.id]">{{ result.promoInfo?.id }}</a>
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Название акции: </span>
        <span class="wl-userinfo-field-value">
            {{ result.promoInfo?.name}}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Состояние акции: </span>
        <span class="wl-userinfo-field-value">
            {{ result.promoInfo?.state | wlBattlePassStatesTransform }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата старта акции: </span>
        <span class="wl-userinfo-field-value">
            {{ result.promoInfo?.startDate | wlDate }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Дата окончания акции: </span>
        <span class="wl-userinfo-field-value">
            {{ result.promoInfo?.endDate | wlDate }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <button class="wl-btn-form-active" (click)="forceCompleteConfirmation()" [disabled]="isForceCompleteForbidden">Пройти уровень башни</button>
    </div>
</div>
<br>
<div class="wl-column">
    <div class="wl-row-first">
        <app-wl-page-header [ignoreTitleSet]="true" header="Полученные попытки за задания дня" *ngIf="result && !loading"></app-wl-page-header>
        <ga-table *ngIf="!!tasksTicketsTableData && result" [data]="tasksTicketsTableData" [breakWordOnly]="true"></ga-table>
    </div>
    <div class="wl-row-second">
        <app-wl-page-header [ignoreTitleSet]="true" header="Полученные дополнительные попытки" *ngIf="result && !loading"></app-wl-page-header>
        <ga-table *ngIf="!!dropTicketsTableData && result" [data]="dropTicketsTableData" [breakWordOnly]="true"></ga-table>
    </div>
</div>
<br>
<app-wl-page-header [ignoreTitleSet]="true" header="История использования попыток" *ngIf="result && !loading"></app-wl-page-header>
<ga-table *ngIf="!!attemptsTableData && result" [data]="attemptsTableData" [breakWordOnly]="true"></ga-table>
<br>
<app-wl-page-header [ignoreTitleSet]="true" header="Прогресс по дням" *ngIf="result && !loading"></app-wl-page-header>
<ga-table *ngIf="!!daysTableData && result" [data]="daysTableData" [breakWordOnly]="true" [hideArrows]="true"></ga-table>

<ng-template #showTask let-rawTask="task">
    <ng-template [ngIf]="typifyTask(rawTask)" let-task="ngIf">
        <div class="wl-task-info-text">
            <div>Id задания: <a [routerLink]="['/menu/battlePass/task/edit', task.taskId]">{{ task.taskId }}</a></div>
            <div>Дата выполнения задания: {{task.completedAt | wlDateTime}}</div>
            <div>Прогресс: {{ mapProgress(task) }}</div>
            <div *ngFor="let subtask of task.subtasks; let i = index">
                <div>Id подзадания: {{ subtask.subtaskId }}</div>
                <div>Id ставок:</div>
                <div *ngIf="!task.isForceCompleted">
                    <span *ngFor="let bet of subtask.entities">
                        <span class="wl-bet-used-for-progress" *ngIf="bet.usedForProgress"> {{ bet.entityId }} </span>
                        <span *ngIf="!bet.usedForProgress"> {{ bet.entityId }} </span>
                        <br/>
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
    <div class="wl-task-info-text" *ngIf="!rawTask">
        <div>Игрок не выполнял задание</div>
    </div>
</ng-template>
