import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StrictFormControl } from '@koddington/ga-common';

@Component({
    selector: 'app-wl-strict-input-boolean',
    templateUrl: './wl-strict-input-boolean.component.html',
    styleUrls: ['./wl-strict-input-boolean.component.scss'],
})
export class WlStrictInputBooleanComponent implements OnInit {
    @Input()
    public control: StrictFormControl<boolean>;
    @Input()
    public label = '';
    @Input()
    public title = '';
    @Input()
    public className = '';

    @Output()
    public userSelect = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}
    public onSelect(): void {
        this.userSelect.emit(this.control.strictValue);
    }
    public onClickLabel(): void {
        if (!this.control.disabled) {
            this.control.strictValue = !this.control.strictValue;
            this.userSelect.emit(this.control.strictValue);
        }
    }
}
