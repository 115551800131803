import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IDropdownStrategy, isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-wl-strict-multi-select-dropdown',
    templateUrl: './wl-strict-multi-select-dropdown.component.html',
    styleUrls: ['./wl-strict-multi-select-dropdown.component.scss'],
})
export class WlStrictMultiSelectDropdownComponent<T> implements OnInit {
    @Input()
    public multiSelectControl: StrictFormControl<T[]>;
    @Input()
    public strategy: IDropdownStrategy<T>;
    @Input()
    public minElementsCount: number;
    @Input()
    public maxElementsCount: number;
    @Input()
    public dropDownPlaceHolder: string;
    @Input()
    public title: string;
    @Output()
    public readonly userSelect = new EventEmitter<void>();
    @Input()
    public shouldShowErrorList = false;
    @Input()
    public clearAfterSelect = false;

    public control: StrictFormControl<T> = new StrictFormControl<T>();
    public selectedElements: T[] = [];

    public get getElementsLabel(): string {
        if (this.selectedElements.length === this.maxElementsCount) {
            return 'Выбрано максимальное количество элементов';
        }
        if (this.selectedElements.length === 0) {
            return 'Выбранные элементы отсутствуют';
        }
        return 'Выбранные элементы';
    }

    public ngOnInit(): void {
        if (this.minElementsCount >= this.maxElementsCount) {
            throw new Error();
        }
        if (this.multiSelectControl.hasStrictValue) {
            this.selectedElements = this.multiSelectControl.strictValue;
        }

        this.multiSelectControl.statusChanges.pipe(
            startWith(this.multiSelectControl.disabled),
            untilDestroyed(this)
        ).subscribe(() => {
            if (this.multiSelectControl.disabled) {
                this.control.disable();
            } else {
                this.control.enable({emitEvent: false});
            }
        });

        this.multiSelectControl.strictErrors.pipe(
            untilDestroyed(this)
        ).subscribe(u => {
            if (this.shouldShowErrorList)
                this.control.setStrictErrors(u);
        });

        this.control.strictValueSource.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.control.hasStrictValue) {
                this.addElement(this.control.strictValue);
            }
        });
    }

    public addElement(selected: T): void {
        if (this.selectedElements.length !== this.maxElementsCount) {
            // tslint:disable-next-line:max-line-length
            const isExist = this.selectedElements.filter((val) => this.strategy.map(val).id === this.strategy.map(selected).id).length > 0;

            if (!isExist) {
                this.selectedElements.push(this.strategy.map(selected).entity);
                this.multiSelectControl.strictValue = [...this.selectedElements];
                this.userSelect.emit();
            }
        }

        if (this.selectedElements.length === this.maxElementsCount) {
            this.control.disable({ emitEvent: false });
        }

        if (this.clearAfterSelect === true) {
            this.control.strictValue = null;
        }
    }

    public removeElement(selected: T): void {
        if (isNullOrUndefined(selected) || this.multiSelectControl.disabled) {
            return;
        }
        this.selectedElements = this.selectedElements.filter((val) => this.strategy.map(val).id !== this.strategy.map(selected).id);
        if (this.selectedElements.length === 0) {
            this.multiSelectControl.strictValue = [];
        } else {
            this.multiSelectControl.strictValue = this.selectedElements;
        }
        if (this.selectedElements.length < this.maxElementsCount) {
            this.control.enable({ emitEvent: false });
        }
        this.userSelect.emit();
    }

    public getElementName(selected: T): string {
        return this.strategy.map(selected).title;
    }
}
