import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { WlBetTypes, WlBetTypesExtensions, WlBetTypesFlags, WlBetTypesFlagsExtensions } from '../../autogen/BattlePass';

export class BetTypeStrategy implements IDropdownStrategy<WlBetTypesFlags> {
    getEntities(): Observable<Array<WlBetTypesFlags>> {
        const entities = EnumExtensions.toArray(WlBetTypesFlags);
        return of(entities);
    }

    map: (model: WlBetTypesFlags) => DropdownItem<WlBetTypesFlags> = model => {
        const item = new DropdownItem<WlBetTypesFlags>();
        item.entity = model;
        item.title = WlBetTypesFlagsExtensions.format(model);
        item.id = Number(model);

        return item;
    }
}
