import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { WlBattlePassStates, WlRateRaceTournamentListModel, WlSeasonLevelModel } from '../../../../autogen/BattlePass';

export class WlSeasonViewModel {
    public id: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public description: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public imageUrl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public payoutDate?: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public startDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public endDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public levelsCount: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public rateRace: StrictFormControl<WlRateRaceTournamentListModel> = StrictFormControlBuilder.buildNullObject(WlRateRaceTournamentListModel);
    public state: StrictFormControl<WlBattlePassStates> = StrictFormControlBuilder.buildNullNumber();
    public levels: StrictFormControl<WlSeasonLevelModel[]> = new StrictFormControl<WlSeasonLevelModel[]>([]);
    public actorLogin: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public editorLogin: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
}
