import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BooleanDropdownStrategy, DropdownItem, StrictFormControl } from '@koddington/ga-common';
import { WlCrmTaskViewModel } from '../../../models/wl-crm-task-view-model';
import {
    BattlePassService,
    WlBattlePassTaskTypes,
    WlBetTypes, WlBetTypesFlags,
    WlCrmTaskTargetTypes,
    WlFiltersTypes,
    WlSportTypeModel,
    WlTaskComparisonConditionsTypes
} from '../../../../autogen/BattlePass';
import { BattlePassBetTypeStrategy } from '../../../strategies/battle-pass-bet-type-strategy';
import { WlBetPeriodTypeStrategy } from '../../../strategies/wl-bet-period-type-strategy';
import { WlCrmBetPlatformsStrategy } from '../../../strategies/wl-crm-bet-platforms-strategy';
import { WlFilterTypeStrategy } from '../../../strategies/wl-filter-type-strategy';
import { WlGamesTypeStrategy } from '../../../strategies/wl-games-type-strategy';
import { WlSportTypeStrategy } from '../../../strategies/wl-sport-type-strategy';
import { WlDialogService } from '../../../../shared/services/wl-dialog.service';
import { WlMarketsParameterViewModel } from '../../../models/wl-markets-parameter-view-model';
import { WlBattlePassMarketsParameterComponent } from '../../wl-battle-pass-markets-parameter/wl-battle-pass-markets-parameter.component';

@UntilDestroy()
@Component({
    selector: 'wl-crm-task-crud-item',
    templateUrl: './wl-crm-task-crud-item.component.html',
    styleUrls: ['./wl-crm-task-crud-item.component.scss', './../wl-crm-task-crud.component.scss'],
})
export class WlCrmTaskCrudItemComponent implements OnInit {
    @Input()
    public taskItem: WlCrmTaskViewModel;
    @Input()
    public isDeleteButtonActive: boolean;
    @Input()
    public isDisabled = false;
    @Input()
    public taskType: WlBattlePassTaskTypes;
    @Input()
    public targetType: WlCrmTaskTargetTypes;
    @Input()
    public collectInExpress: StrictFormControl<boolean>;
    @Input()
    public taskTypeControl: StrictFormControl<WlBattlePassTaskTypes>;
    @Input()
    public isGrouped: boolean;
    @Input()
    public isRecurrent: boolean;
    @Output()
    public change = new EventEmitter<void>();
    @Output()
    delEmitter = new EventEmitter<void>();
    @Output()
    addEmitter = new EventEmitter<void>();

    public moreOrEqualStrongConditionalType: WlTaskComparisonConditionsTypes[] = [WlTaskComparisonConditionsTypes.MoreEqual];
    public singleValuesConditionalTypes: WlTaskComparisonConditionsTypes[] = [
        WlTaskComparisonConditionsTypes.More,
        WlTaskComparisonConditionsTypes.MoreEqual,
        WlTaskComparisonConditionsTypes.Less,
        WlTaskComparisonConditionsTypes.LessEqual,
        WlTaskComparisonConditionsTypes.Equal
    ];

    public betTypeStrategy: BattlePassBetTypeStrategy;
    public betPeriodStrategy: WlBetPeriodTypeStrategy;
    public betPlatformStrategy: WlCrmBetPlatformsStrategy;
    public filterStrategy: WlFilterTypeStrategy;
    public booleanStrategy: BooleanDropdownStrategy;

    constructor(private readonly _battlePassService: BattlePassService,
                private readonly _gamesStrategy: WlGamesTypeStrategy,
                private readonly _sportTypeStrategy: WlSportTypeStrategy,
                private readonly _dialogs: WlDialogService) {
    }

    private setBetTypeRule = () => this.collectInExpress.strictValue ? [WlBetTypes.Any, WlBetTypes.System, WlBetTypes.Ordinar] : [WlBetTypes.System];

    ngOnInit(): void {
        this.betTypeStrategy =  new BattlePassBetTypeStrategy(this.setBetTypeRule);
        this.betPeriodStrategy = new WlBetPeriodTypeStrategy();
        this.betPlatformStrategy = new WlCrmBetPlatformsStrategy();
        this.filterStrategy = new WlFilterTypeStrategy();
        this.booleanStrategy = new BooleanDropdownStrategy(false);
        this.setDisabled();
        this.subscribeOnChanges();
    }

    public delete(): void {
        this.delEmitter.emit();
    }

    public addTask(): void {
        this.addEmitter.emit();
    }

    public addMarket(): void {
        const toAdd = new WlMarketsParameterViewModel();
        this._dialogs.openDialog(WlBattlePassMarketsParameterComponent, { market: toAdd }, 900, 400)
            .pipe(filter((resp) => !!resp))
            .subscribe((res) => {
                this.taskItem.activeMarkets.strictValue.push(res);
            });
    }

    public editMarket(index: number): void {
        const toUpdate = this.getMarketForUpdate(this.taskItem.activeMarkets.strictValue[index]);
        this._dialogs.openDialog(WlBattlePassMarketsParameterComponent, { market: toUpdate }, 900, 400)
            .pipe(filter((resp) => !!resp))
            .subscribe((res) => {
                this.taskItem.activeMarkets.strictValue[index] = res;
            });
    }

    public deleteMarket(index: number): void {
        this.taskItem.activeMarkets.strictValue = this.taskItem.activeMarkets.strictValue.filter((u, k) => k !== index);
    }

    public setInitSportType(): (items: DropdownItem<WlSportTypeModel>[]) => DropdownItem<WlSportTypeModel> {
        if (this.taskItem.sportType.hasStrictValue) {
            return (items) => items.find((value) => value.entity?.name === this.taskItem.sportType.strictValue.name);
        }
        if (this.taskItem.id.hasStrictValue) {
            if (!this.taskItem.sportType.hasStrictValue) {
                return null;
            } else {
                return (items) => items.find((value) => value.entity?.name === this.taskItem.sportType.strictValue.name);
            }
        }
    }

    private setDisabled(): void {
        if (this.isDisabled !== true) {
            return;
        }

        this.taskItem.betTypes.disable();
        this.taskItem.betCount.disable();
        this.taskItem.sportType.disable();
        this.taskItem.betPeriod.disable();
        this.taskItem.championshipId.disable();
        this.taskItem.isExcludedGames.disable();
        this.taskItem.games.disable();
        this.taskItem.isExcludedSportType.disable();
        this.taskItem.sportTypes.disable();
        this.taskItem.depositsCount.disable();

        this.taskItem.severalBetsSumRange.strictValue.value.disable();
        this.taskItem.severalBetsSumRange.strictValue.condition.disable();
        this.taskItem.eventCountExpressRange.strictValue.value.disable();
        this.taskItem.eventCountExpressRange.strictValue.condition.disable();
        this.taskItem.betRateRange.strictValue.value.disable();
        this.taskItem.betRateRange.strictValue.condition.disable();
        this.taskItem.lineRateRange.strictValue.value.disable();
        this.taskItem.lineRateRange.strictValue.condition.disable();
        this.taskItem.betSumRange.strictValue.value.disable();
        this.taskItem.betSumRange.strictValue.condition.disable();
        this.taskItem.totalWinAmountRange.strictValue.value.disable();
        this.taskItem.totalWinAmountRange.strictValue.condition.disable();
        this.taskItem.betWinAmountRange.strictValue.value.disable();
        this.taskItem.betWinAmountRange.strictValue.condition.disable();
        this.taskItem.depositsSumRange.strictValue.value.disable();
        this.taskItem.depositsSumRange.strictValue.condition.disable();
        this.taskItem.totalDepositsAmountRange.strictValue.value.disable();
        this.taskItem.totalDepositsAmountRange.strictValue.condition.disable();
        this.taskItem.countFreeBets.disable();
        this.taskItem.countBetsWithoutResult.disable();

        this.taskItem.betPlatforms.disable();
    }

    private subscribeOnChanges() {
        this.collectInExpress.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.betTypeStrategy.updateEntities();
            if (this.isDepositsType)
                this.taskItem.betTypes.strictValue = null;
        });

        this.taskTypeControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.taskTypeControl.strictValue === WlBattlePassTaskTypes.Bets)
                return;

            this.taskItem.countBetsWithoutResult.strictValue = false;

            if (this.taskTypeControl.strictValue === WlBattlePassTaskTypes.Win)
                return;

            this.taskItem.countFreeBets.strictValue = false;
        });
    }

    private getMarketForUpdate(market: WlMarketsParameterViewModel): WlMarketsParameterViewModel {
        const toUpdate = new WlMarketsParameterViewModel();
        toUpdate.market.strictValue = market.market.strictValue;
        toUpdate.ftsParameterA.strictValue = market.ftsParameterA.strictValue;
        toUpdate.ftsParameterB.strictValue = market.ftsParameterB.strictValue;
        toUpdate.ftsParameterC.strictValue = market.ftsParameterC.strictValue;

        return toUpdate;
    }

    get isBetType(): boolean {
        return this.taskType === WlBattlePassTaskTypes.Bets;
    }

    get isWinType(): boolean {
        return this.taskType === WlBattlePassTaskTypes.Win;
    }

    get isDepositsType(): boolean {
        return this.taskType === WlBattlePassTaskTypes.Deposits;
    }

    get isRedeemType(): boolean {
        return this.taskType === WlBattlePassTaskTypes.Redeem;
    }

    get isByCountType(): boolean {
        return this.targetType === WlCrmTaskTargetTypes.ByCount;
    }

    get isBySumType(): boolean {
        return this.targetType === WlCrmTaskTargetTypes.BySum;
    }

    get isBetExpressType(): boolean {
        return !this.isDepositsType
            && this.taskItem.betTypes.strictValue.length === 1
            && this.taskItem.betTypes.strictValue[0] === WlBetTypesFlags.Express;
    }

    get isTaskExist(): boolean {
        return this.taskItem.id.hasStrictValue;
    }

    get isBetExpressOrAnyBetType(): boolean {
        // tslint:disable-next-line:no-bitwise
        console.log(this.taskItem.betTypes.strictValue);
        return !this.isDepositsType && (
            this.taskItem.betTypes.strictValue.includes(WlBetTypesFlags.Express)
            || this.taskItem.betTypes.strictValue.includes(WlBetTypesFlags.System));
    }

    get isAddTaskDisabled(): boolean {
        return this.isDisabled || this.isRecurrent || this.isAddTaskDisabledByType;
    }

    get isAddTaskDisabledByType(): boolean {
        return this.taskType === WlBattlePassTaskTypes.Deposits || this.taskType === WlBattlePassTaskTypes.Other ||
            (this.isGrouped && this.targetType === WlCrmTaskTargetTypes.BySum);
    }

    get maxGameItems(): number {
        const countGames = this.gamesStrategy.countEntities();
        if (this.taskItem.isExcludedGames.strictValue === WlFiltersTypes.Excluding) {
            return countGames - 1;
        }

        if (this.taskItem.isExcludedGames.strictValue === WlFiltersTypes.Including) {
            return 1;
        }

        return countGames;
    }

    get isCollectInExpress(): boolean {
        return this.collectInExpress.hasStrictValue && this.collectInExpress.strictValue;
    }

    get isShowSeveralBetsSumRange(): boolean {
        if (this.isGrouped) {
            return this.isBySumType === false;
        }

        return (this.isBetType || this.isRedeemType) && this.isBySumType;
    }

    get gamesStrategy(): WlGamesTypeStrategy {
        return this._gamesStrategy;
    }

    get sportTypeStrategy(): WlSportTypeStrategy {
        return this._sportTypeStrategy;
    }
}
