import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { DailyTasksPlayersCardsSelectorViewModel } from './daily-tasks-players-cards-selector-view-model';
import {
    WlCrmTaskGroupSearchModel,
    WlDailyTaskStates,
    WlDtFreeBetLootBoxListModel, WlPromoTypes,
} from '../../../../autogen/DailyTasks';
import { PickemOutcomeViewModel } from './pickem-outcome-view-model';
import { WlDailyTasksLimitsConsts } from '../../../consts/wl-daily-tasks-limits-consts';

export class DailyTasksDaysCrudViewModel {
    public id: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public promoType: StrictFormControl<WlPromoTypes> = StrictFormControlBuilder.buildNullNumber();
    public startDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public endDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public crmTaskGroup: StrictFormControl<WlCrmTaskGroupSearchModel> = StrictFormControlBuilder.buildNullObject(WlCrmTaskGroupSearchModel);
    public lootBox: StrictFormControl<WlDtFreeBetLootBoxListModel> = StrictFormControlBuilder.buildNullObject(WlDtFreeBetLootBoxListModel);
    public description: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public isOffPlayDay: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public isUnconditionalDay: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public isActive: StrictFormControl<boolean> = StrictFormControlBuilder.buildNullBoolean();
    public playerCards: StrictFormControl<DailyTasksPlayersCardsSelectorViewModel[]> = new StrictFormControl<DailyTasksPlayersCardsSelectorViewModel[]>([]);
    public state: StrictFormControl<WlDailyTaskStates> = StrictFormControlBuilder.buildNullNumber();

    public usersForecastDeadline: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public pickemId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public pickemText: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public forecastTypeImageUrl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public forecastTypeText: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public outcomes: StrictFormControl<PickemOutcomeViewModel[]> = new StrictFormControl<PickemOutcomeViewModel[]>(
        [
            ...Array(WlDailyTasksLimitsConsts.PickemCardsCount).keys()
        ].map((order) => new PickemOutcomeViewModel(order + 1))
    );
}
