import { isNullOrUndefined, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { WlGameTaskLiteViewModel } from '../../../models/wl-game-task-lite-view-model';
import { WlCrmTaskLiteViewModel } from '../../../models/wl-crm-task-lite-view-model';
import { WlNotificationTemplate } from '../../../../autogen/BattlePass';
import { WlLevelGameLootBoxViewModel } from '../../../models/wl-level-game-loot-box-view-model';
import { WlLevelFreeBetLootBoxViewModel } from '../../../models/wl-level-free-bet-loot-box-view-model';

export class WlLevelViewModel {
    public id: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public isGamblerLevel: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public description: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public imageUrl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public iconUrl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public inactiveIconUrl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public order: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public startDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public crmTaskGroups: StrictFormControl<WlCrmTaskLiteViewModel[]> = new StrictFormControl<WlCrmTaskLiteViewModel[]>([]);
    public gameTasks: StrictFormControl<WlGameTaskLiteViewModel[]> = new StrictFormControl<WlGameTaskLiteViewModel[]>([]);
    public template: StrictFormControl<WlNotificationTemplate> = StrictFormControlBuilder.buildNullObject(WlNotificationTemplate);
    public gameLootBoxes: StrictFormControl<WlLevelGameLootBoxViewModel[]> = new StrictFormControl<WlLevelGameLootBoxViewModel[]>([]);
    public freeBetLootBoxes: StrictFormControl<WlLevelFreeBetLootBoxViewModel[]> = new StrictFormControl<WlLevelFreeBetLootBoxViewModel[]>([]);
    public isActive: StrictFormControl<boolean> = new StrictFormControl<boolean>(null);

    public overallTasksCount(taskSelected: boolean): number {
        const crmTasksCount = this.crmTaskGroups.strictValue?.filter(u => taskSelected ? true : !isNullOrUndefined(u.task)).length ?? 0;
        const gameTasksCount = this.gameTasks.strictValue?.filter(u => taskSelected ? true : !isNullOrUndefined(u.task)).length ?? 0;

        return (crmTasksCount + gameTasksCount);
    }
}
