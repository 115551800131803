import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WlPromotionViewModel } from './models/wl-promotion-view-model';
import { Subject } from 'rxjs';
import { formatDateTime, GaConfirmationService, GaMessagingService, GaStrictModelFactory, isNullOrUndefined, StrictError, StrictFormControl } from '@koddington/ga-common';
import {
    DailyTasksPromotionsService,
    PickemsService,
    SetPickemWinOutcomeForm,
    WlDailyTaskStates,
    WlDailyTaskStatesExtensions,
    WlDtPickemOutcomeInfo,
    WlDtPickemWinOutcomeInfo,
    WlDtPromotion,
    WlDtPromotionDay,
    WlPromotionsCreateForm,
    WlPromotionsUpdateForm,
    WlPromoTypes
} from '../../../autogen/DailyTasks';
import { WlPromotionsCrudValidator } from './validators/wl-promotions-crud-validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, finalize, map, take } from 'rxjs/operators';
import dayjs from 'dayjs';
import { WlBattlePassStates } from '../../../autogen/Shared';
import { DailyTaskDaysAutocompleteStrategy } from '../../strategies/daily-task-days-autocomplete-strategy';
import { WlDailyTasksDaysSelectorViewModel } from './models/wl-daily-tasks-days-selector-view-model';
import { DailyTaskDaysScrollServiceAdapter } from '../../services/wrapper-services/daily-task-days.scroll-service-adapter';
import { environment } from '../../../../../environments/environment';
import { WlBattlePassCRMTaskScrollStrategy } from '../../../battle-pass/strategies/wl-battle-pass-crm-task-scroll-strategy';
import { WlBattlePassWrapperService } from '../../../battle-pass/services/wrapper-service/wl-battle-pass-wrapper-service';
import { WlPickemOutcomeSelectorValidator } from './validators/wl-pickem-outcome-selector.vialidator';
import { WlPickemOutcomeSelectorViewModel } from './models/wl-pickem-outcome-selector.view-model';
import { WlDialogService } from '../../../shared/services/wl-dialog.service';
import { WlPromotionsPlacesComponent } from '../wl-promotions-places/wl-promotions-places.component';
import { WlDtPromoPlacesContainer } from '../../models/wl-dt-promo-places.container';
import { WlPromoTypesDropdownStrategy } from '../../strategies/wl-promo-types.dropdown-strategy';
import gaMap = GaStrictModelFactory.gaMap;
import KeyMap = GaStrictModelFactory.KeyMap;
import { DailyTasksConsts } from '../../consts/daily-tasks.consts';

@UntilDestroy()
@Component({
    selector: 'app-wl-promotions-crud',
    templateUrl: './wl-promotions-crud.component.html',
    styleUrls: ['./wl-promotions-crud.component.scss'],
    providers: [DailyTaskDaysAutocompleteStrategy]
})
export class WlPromotionsCrudComponent implements OnInit {
    public viewModel: WlPromotionViewModel = new WlPromotionViewModel();
    public loading = true;
    public readonly userManipulationsSource = new Subject<void>();
    public errors: StrictError[] = [];

    protected readonly StrictFormControl = StrictFormControl;
    protected promoPlaces = new WlDtPromoPlacesContainer();

    protected readonly WlPromoTypes = WlPromoTypes;
    protected readonly DailyTasksConsts = DailyTasksConsts;

    private readonly validator = new WlPromotionsCrudValidator();
    private readonly outcomeValidator = new WlPickemOutcomeSelectorValidator();

    protected readonly crmTaskGroupStrategy = new WlBattlePassCRMTaskScrollStrategy(
        inject(WlBattlePassWrapperService),
        _ => {
        }
    );

    private readonly dayPromoTypeRule: () => WlPromoTypes = () => {
        return this.viewModel.type.strictValue;
    }

    constructor(
        protected readonly promoTypesStrategy: WlPromoTypesDropdownStrategy,
        private readonly _router: Router,
        private readonly _route: ActivatedRoute,
        private readonly _messaging: GaMessagingService,
        private readonly _service: DailyTasksPromotionsService,
        private readonly _daysApiWrapper: DailyTaskDaysScrollServiceAdapter,
        private readonly _pickemsService: PickemsService,
        private readonly _confirmation: GaConfirmationService,
        private readonly _dialogs: WlDialogService
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];
        this.load();
    }

    public save(): void {
        this.loading = true;
        this.errors = this.validator.validate(this.viewModel);
        if (this.errors.length > 0) {
            this.loading = false;
            return;
        }

        if (this.viewModel.id.hasStrictValue) {
            this.update();
        } else {
            this.create();
        }
    }

    public addItem(): void {
        this.viewModel.days.strictValue.push(new WlDailyTasksDaysSelectorViewModel(this._daysApiWrapper, this.dayPromoTypeRule));
        this.userManipulationsSource.next();
    }

    public deleteDay(index: number): void {
        if (this.viewModel.days.strictValue.length <= 1) {
            return;
        }
        this.viewModel.days.strictValue = this.viewModel.days.strictValue.filter((_, k) => k !== index);
        this.recalculateTechnicalEndDate();
    }

    public canDeleteDay(index: number): boolean {
        if (this.viewModel.days.strictValue.length <= 1) {
            return false;
        }

        return !this.viewModel.days?.strictValue[index]?.day?.hasStrictValue ? true :
            this.viewModel.days?.strictValue[index]?.day?.strictValue?.state === WlDailyTaskStates.NotStarted;
    }

    public changeShowPickemFlag(dayVm: WlDailyTasksDaysSelectorViewModel): void {
        dayVm.pickemSelectorInfo.showPickemForecastSelector = !dayVm.pickemSelectorInfo.showPickemForecastSelector;
    }

    public routeToDay(day: WlDtPromotionDay): any[] | null {
        return !isNullOrUndefined(day) ? ['/menu/dailyTasks/days/update/', day.id] : null;
    }

    public getDayStartDate(day: WlDtPromotionDay): string | null {
        return !isNullOrUndefined(day?.startDate) ? formatDateTime(day.startDate) : null;
    }

    public getDayEndDate(day: WlDtPromotionDay): string | null {
        return !isNullOrUndefined(day?.endDate) ? formatDateTime(day.endDate) : null;
    }

    public getDayState(day: WlDtPromotionDay): string | null {
        return !isNullOrUndefined(day?.state) ? WlDailyTaskStatesExtensions.format(day.state) : null;
    }

    public selectWinOutcome(dayVm: WlDailyTasksDaysSelectorViewModel, winOutcomeId: number): void {
        dayVm.pickemSelectorInfo.winOutcomeId.strictValue = winOutcomeId;
    }

    public isOutcomeSelected(dayVm: WlDailyTasksDaysSelectorViewModel, outcomeId: number) {
        return dayVm.pickemSelectorInfo.winOutcomeId.strictValue === outcomeId;
    }

    public getSelectWinOutcomeBtnText(dayVm: WlDailyTasksDaysSelectorViewModel, outcomeId: number): string {
        return (this.isOutcomeSelected(dayVm, outcomeId))
            ? 'Исход выбран'
            : 'Выбрать исход';
    }

    public setWinOutcome(dayVm: WlDailyTasksDaysSelectorViewModel): void {
        const form = GaStrictModelFactory.fromStrictToModel(
            SetPickemWinOutcomeForm,
            dayVm.pickemSelectorInfo,
            null,
            null,
            'showPickemForecastSelector'
        );

        this._confirmation
            .openDialog('Выбрать исход?')
            .pipe(
                take(1),
                filter(res => !!res),
                map(() => this._pickemsService.setWinOutcome(form)
                              .pipe(
                                  take(1),
                                  filter(res => !this._messaging.tryShowError(res)),
                                  finalize(() => this.userManipulationsSource.next())
                              ).subscribe(_ => {
                        this._messaging.showMessage('Прогноз выбран');
                        const pickemInfo = dayVm.day.strictValue.pickemInfo;
                        pickemInfo.winOutcome = new WlDtPickemWinOutcomeInfo(pickemInfo.outcomes.find(u => u.id === form.winOutcomeId));
                        pickemInfo.winOutcome.winDescription = form.description;
                    })
                ))
            .subscribe();
    }

    protected dayWinOutcome(dayVm: WlDailyTasksDaysSelectorViewModel): WlDtPickemOutcomeInfo {
        return dayVm?.day?.strictValue?.pickemInfo?.winOutcome;
    }

    protected hasPickem(dayVm: WlDailyTasksDaysSelectorViewModel): boolean {
        return !!dayVm.day.strictValue?.pickemInfo;
    }

    protected recalculateTechnicalEndDate(): void {
        this.viewModel.technicalEndDate.strictValue = dayjs(this.days.reduce((prev, curr) => {
            return (prev && prev.day.strictValue.endDate > curr.day.strictValue.endDate)
                ? prev
                : curr;
        }).day.strictValue.endDate);
        this.userManipulationsSource.next();
    }


    protected canSelectWinOutcome(dayVm: WlDailyTasksDaysSelectorViewModel): boolean {
        return dayjs(dayVm.day.strictValue.pickemInfo.usersForecastDeadline) <= dayjs();
    }

    protected canSetWinOutcome(dayVm: WlDailyTasksDaysSelectorViewModel): boolean {
        return this.canSelectWinOutcome(dayVm) && this.outcomeValidator.validate(dayVm.pickemSelectorInfo).length === 0;
    }

    private load(): void {
        if (this.viewModel.id.hasStrictValue) {
            this._service
                .get(this.viewModel.id.value)
                .pipe(
                    filter((value) => !this._messaging.tryShowError(value)),
                    untilDestroyed(this)
                )
                .subscribe((result) => {
                    this.loadModel(result.result);
                    this.loading = false;
                    this.setControlsState();
                    this.subscriptionToValidate();
                });
        } else {
            this.loading = false;
            this.subscriptionToValidate();
            this.subscriptionToClear();
        }
    }

    protected openPromoPlacesTable(): void {
        this._dialogs
            .openDialog(WlPromotionsPlacesComponent, {container: this.promoPlaces, promoId: this.viewModel.id.strictValue}, 1050, 700)
            .subscribe();
    }

    protected canHavePromoTaskPromoTypes(type: WlPromoTypes): boolean {
        return DailyTasksConsts.canHavePromoTaskPromoTypes.includes(type);
    }

    private create(): void {
        const form = this.createAddForm();

        this._service
            .add(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                finalize(() => (this.loading = false)),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.showListPromotions();
            });
    }

    private createAddForm(): WlPromotionsCreateForm {
        return GaStrictModelFactory.fromStrictToModel(
            WlPromotionsCreateForm,
            this.viewModel,
            null,
            [this.keyMapDays(), this.keyMapPromo()],
            'technicalEndDate'
        );
    }

    private createUpdateForm(): WlPromotionsUpdateForm {
        return GaStrictModelFactory.fromStrictToModel(
            WlPromotionsUpdateForm,
            this.viewModel,
            null,
            [this.keyMapDays(), this.keyMapPromo()],
            'technicalEndDate'
        );
    }

    private keyMapDays(): KeyMap {
        return {
            sourceKey: 'days',
            targetKey: 'daysIds',
            newValue: this.viewModel.days.hasStrictValue
                ? this.viewModel.days.strictValue.map(u => u.day.strictValue.id)
                : null
        };
    }

    private keyMapPromo(): KeyMap {
        return {
            sourceKey: 'task', targetKey: 'crmTaskGroupId', newValue: this.viewModel.task.strictValue?.id
        };
    }

    private subscriptionToValidate(): void {
        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.viewModel);
        });
        this.userManipulationsSource.next();
    }

    private subscriptionToClear(): void {
        this.viewModel.type.valueChanges.pipe(untilDestroyed(this)).subscribe(u => {
            if (u !== WlPromoTypes.LootBox) {
                this.viewModel.freeBetId.strictValue = null;
            }

            if (u !== WlPromoTypes.Pickem) {
                this.viewModel.responsiblePhonesFile.strictValue = null;
                this.viewModel.usersListFileName.strictValue = null;
                this.viewModel.fileLoaded.strictValue = null;
                this.viewModel.task.strictValue = null;
            }

            if (!this.canHavePromoTaskPromoTypes(u)) {
                this.viewModel.task.strictValue = null;
            }

            if (!DailyTasksConsts.mobileDataRequiredPromoTypes.includes(u)) {
                this.viewModel.textColor.strictValue = null;
                this.viewModel.headerColor.strictValue = null;
                this.viewModel.headerImageUrl.strictValue = null;
            }

            this.viewModel.days.strictValue.length = 0;

            this.userManipulationsSource.next();
        });
    }

    private loadModel(model: WlDtPromotion): void {
        GaStrictModelFactory.fromModelToStrict(this.viewModel, model, [
            {
                sourceMapProperty: 'days',
                targetMapProperty: 'days',
                mapObject: gaMap(model.days, (val) => {
                    const mapped = new WlDailyTasksDaysSelectorViewModel(this._daysApiWrapper, this.dayPromoTypeRule);
                    mapped.day.strictValue = val;
                    if (isNullOrUndefined(val.pickemInfo)) {
                        return mapped;
                    }

                    mapped.pickemSelectorInfo = new WlPickemOutcomeSelectorViewModel();
                    mapped.pickemSelectorInfo.pickemId.strictValue = val.pickemInfo.id;
                    mapped.pickemSelectorInfo.winOutcomeId.strictValue = val.pickemInfo.winOutcome?.id;
                    mapped.pickemSelectorInfo.description.strictValue = val.pickemInfo.winOutcome?.winDescription;
                    return mapped;
                }),
            }
        ], [
            {sourceKey: 'startDate', targetKey: 'startDate', newValue: dayjs(model.startDate)},
            {sourceKey: 'endDate', targetKey: 'endDate', newValue: dayjs(model.endDate)},
            {sourceKey: 'technicalEndDate', targetKey: 'technicalEndDate', newValue: dayjs(model.technicalEndDate)}
        ], null);

        this.viewModel.task.strictValue = model.promoTask?.taskGroup;

        this.promoPlaces.places = model.promoPlaces;
    }

    private showListPromotions(): void {
        this._router.navigate(['/menu/dailyTasks/promotions']);
    }

    private update(): void {
        const form = this.createUpdateForm();

        this._service
            .update(form)
            .pipe(
                filter((value) => !this._messaging.tryShowError(value)),
                finalize(() => this.loading = false),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.showListPromotions();
            });
    }

    private setControlsState(): void {
        if (this.isPromotionExist) {
            this.viewModel.type.disable();
        }

        if (!this.canUpdate) {
            this.viewModel.startDate.disable();
            this.viewModel.name.disable();
            this.viewModel.freeBetId.disable();
            this.viewModel.task.disable();
            this.viewModel.days.strictValue.forEach((day) => {
                if (day.day?.strictValue?.state !== WlDailyTaskStates.NotStarted) {
                    day.day.disable();
                }
            });
            if (this.viewModel.state.strictValue === WlBattlePassStates.Finished) {
                this.viewModel.endDate.disable();
                this.viewModel.daysCount.disable();
                this.viewModel.isLightTheme.disable();
                this.viewModel.textColor.disable();
                this.viewModel.headerColor.disable();
                this.viewModel.headerImageUrl.disable();
            }
        }
    }

    get canUpdate(): boolean {
        return this.viewModel.state.strictValue === WlBattlePassStates.NotStarted;
    }

    get isFinished(): boolean {
        return this.viewModel?.state?.strictValue === WlBattlePassStates.Finished;
    }

    get purpose(): string {
        if (this.viewModel.id.hasStrictValue) {
            return `Редактирование акции № ${this.viewModel.id.strictValue}`;
        }
        return 'Создание акции';
    }

    get saveButtonText(): string {
        return this.viewModel.id.hasStrictValue
            ? 'Сохранить акцию'
            : 'Создать акцию';
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }

    get days(): WlDailyTasksDaysSelectorViewModel[] {
        return this.viewModel.days.strictValue;
    }

    get isPromotionExist(): boolean {
        return this.viewModel.id.hasStrictValue;
    }

    get hasPromoPlacesInfo(): boolean {
        return this.isPromotionExist && this.promoPlaces.places?.length > 0;
    }

    get downloadUserFileUrl(): string {
        const baseUrl = window.location.protocol + '//' + (environment.host ? environment.host : window.location.host);
        return baseUrl + '/bpadmin/DailyTasksPromotions/DownloadUsersFile?promoId=' + this.viewModel.id.strictValue;
    }

    get routerLinkForCrmTask(): any[] | null {
        return this.viewModel?.task?.hasStrictValue
            ? ['/menu/battlePass/task/edit/', this.viewModel?.task?.strictValue.id]
            : null;
    }

    get promoType(): WlPromoTypes {
        return this.viewModel.type.strictValue;
    }

    get hasPromoType(): boolean {
        return !isNullOrUndefined(this.promoType);
    }
}
