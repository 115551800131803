import { Routes } from '@angular/router';
import { DailyTasksDaysListComponent } from './components/daily-tasks-days-list/daily-tasks-days-list.component';
import { DailyTasksDaysCrudComponent } from './components/daily-tasks-days-crud/daily-tasks-days-crud.component';
import { WlPromotionsListComponent } from './components/wl-promotions-list/wl-promotions-list.component';
import { WlPromotionsCrudComponent } from './components/wl-promotions-crud/wl-promotions-crud.component';
import { DailyTasksLootBoxesListComponent } from './components/loot-boxes/daily-tasks-loot-boxes-list/daily-tasks-loot-boxes-list.component';
import { DailyTasksLootBoxesCrudComponent } from './components/loot-boxes/daily-tasks-loot-boxes-crud/daily-tasks-loot-boxes-crud.component';
import { DailyTaskUserInfoComponent } from './components/daily-task-user-info/daily-task-user-info.component';
import { TowersUserProfileComponent } from "./components/towers-user-profile/towers-user-profile.component";


export const DailyTasksRoutes: Routes = [
    {
        path: 'dailyTasks',
        data: {
            routeName: 'daily tasks',
        },
        children: [
            {
                path: 'promotions',
                component: WlPromotionsListComponent,
                data: {
                    highlightMenuItem: 'promotions',
                    routeName: 'Акции',
                },
            },
            {
                path: 'promotions/add',
                component: WlPromotionsCrudComponent,
                data: {
                    highlightMenuItem: 'promotions',
                    routeName: 'Создание акции',
                },
            },
            {
                path: 'promotions/update/:id',
                component: WlPromotionsCrudComponent,
                data: {
                    highlightMenuItem: 'promotions',
                    routeName: 'Редактирование акции',
                },
            },
            {
                path: 'loot-boxes',
                children: [
                    {
                        path: 'list',
                        component: DailyTasksLootBoxesListComponent
                    },
                    {
                        path: 'create',
                        component: DailyTasksLootBoxesCrudComponent
                    },
                    {
                        path: 'update/:id',
                        component: DailyTasksLootBoxesCrudComponent
                    },
                ]
            },
            {
                path: 'days',
                component: DailyTasksDaysListComponent,
                data: {
                    highlightMenuItem: 'days',
                    routeName: 'Дни',
                },
            },
            {
                path: 'days/create',
                component: DailyTasksDaysCrudComponent,
                data: {
                    highlightMenuItem: 'days',
                    routeName: 'Создание дня',
                },
            },
            {
                path: 'days/update/:id',
                component: DailyTasksDaysCrudComponent,
                data: {
                    highlightMenuItem: 'days',
                    routeName: 'Редактирование дня',
                },
            },
            {
                path: 'userInfo',
                component: DailyTaskUserInfoComponent,
                data: {
                    highlightMenuItem: 'userInfo',
                    routeName: 'Профиль пользователя',
                },
            },
            {
                path: 'towerUserProfile',
                component: TowersUserProfileComponent,
                data: {
                    highlightMenuItem: 'towerUserProfile',
                    routeName: 'Профиль пользователя (Towers)',
                },
            },
        ],
    },
];

