import { Routes } from '@angular/router';
import { TrackerGamblerBindingProfileComponent } from './components/tracker-gambler-profile/tracker-gambler-binding-profile.component';
import { NewYearProfileComponent } from './components/new-year-profile/new-year-profile.component';

export const TrackerRoutes: Routes = [
  {
    path: '',
    children: [
      {
            path: 'gamblerBindingProfile',
            component: TrackerGamblerBindingProfileComponent,
      },
        {
            path: 'happyNewYearProfile',
            component: NewYearProfileComponent,
        },
    ],
  }
];
