import { AbstractModelValidator, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import dayjs from 'dayjs';
import { WlPromotionViewModel } from '../models/wl-promotion-view-model';
import { WlDailyTasksDaysSelectorValidator } from './wl-daily-tasks-days-selector-validator';
import { WlPromoTypes } from '../../../../autogen/DailyTasks';
import { DailyTasksConsts } from '../../../consts/daily-tasks.consts';

export class WlPromotionsCrudValidator extends AbstractModelValidator<WlPromotionViewModel> {
    private selectorValidator = new WlDailyTasksDaysSelectorValidator();
    private readonly mobileThemeDataRequired: (u: WlPromotionViewModel) => boolean  = (u) => {
        return DailyTasksConsts.mobileDataRequiredPromoTypes.includes(u.type.strictValue);
    }

    constructor() {
        super();

        this.ruleForControl((u) => u.name)
            .notEmptyString();

        this.ruleForControl(u => u.type)
            .required('Тип акции обязятелен');

        this.ruleForControlIf(u => u.task, u => DailyTasksConsts.promoTaskRequiredPromoTypes.includes(u.type.strictValue))
            .required('Промо-задание обязательно');

        this.ruleForControlIf((u) => u.headerColor, this.mobileThemeDataRequired)
            .notEmptyString();

        this.ruleForControlIf((u) => u.headerImageUrl, this.mobileThemeDataRequired)
            .notEmptyString();

        this.ruleForControlIf((u) => u.textColor, this.mobileThemeDataRequired)
            .notEmptyString();

        this.ruleForControl((u) => u.startDate)
            .required('Дата начала акции обязательна');

        this.ruleForControl(u => u.technicalEndDate)
            .requiredAndNotZero('Дата технического окончания обязательна');

        this.ruleForControl((u) => u.endDate)
            .required('Дата завершения акции обязательна');

        this.ruleForControl((u) => u.daysCount)
            .requiredAndNotZero('Укажите количество дней акции');

        this.ruleForControl((u) => u.endDate).customRule((fieldValue, model) => {
            if (model.startDate.hasStrictValue && model.endDate.hasStrictValue && fieldValue < model.startDate.strictValue) {
                return new StrictError('endDateError', 'Дата завершения акции должна быть больше даты начала');
            }

            return null;
        });

        this.ruleForControl((u) => u.days).customRule((fieldValue, model) => {
            if (!model.startDate.hasStrictValue || !model.endDate.hasStrictValue)
                return null;

            if (!model.days.hasStrictValue || model.days.strictValue.length === 0)
                return null;

            const days = model.days.strictValue
                              .sort((first, second) => this.comparer(first.day.strictValue?.startDate) - this.comparer(second.day.strictValue?.startDate));
            let hasErrors = this.selectorValidator.validateArray(fieldValue).length !== 0;

            days.forEach((val) => {
                if (model.startDate.strictValue.isAfter(val?.day?.strictValue?.startDate)
                    || model.endDate.strictValue.isBefore(val?.day?.strictValue?.endDate)) {
                    val.day.setStrictErrors([new StrictError('dayDatesError', 'Даты дня находятся вне диапазона акции')]);
                    hasErrors = true;
                }
            });

            for (let i = 0; i < days.length - 1; i++) {
                if (this.isAfterOrSame(dayjs(days[i]?.day?.strictValue?.endDate), dayjs(days[i + 1]?.day?.strictValue?.startDate))) {
                    days[i + 1].day.setStrictErrors([new StrictError('dayDatesError', 'Игровой день пересекается с предыдущим днём')]);
                    hasErrors = true;
                }
            }

            return hasErrors ? new StrictError('daysErrors', 'Некорректные дни') : null;
        });

        this.ruleForControl((u) => u.daysCount).customRule((fieldValue, model) => {
            if (fieldValue && fieldValue !== model.days.strictValue.length) {
                return new StrictError('endDateError', 'Количество добавленых дней должно соответствовать количеству дней акции');
            }

            return null;
        });

        this.addDailyTasksLootBoxRules();
        this.addPickemRules();
    }

    private addDailyTasksLootBoxRules(): void {
        this.ruleForControlIf(u => u.freeBetId, v => v.type.strictValue === WlPromoTypes.LootBox)
            .required('Укажите id фрибета')
            .biggerThan(0, 'Id фрибета должен быть больше 0')
            .isInteger('Id фрибета должен быть целым числом');
    }

    private addPickemRules(): void {
        this.ruleForControlIf((u) => u.fileLoaded, v => v.type.strictValue === WlPromoTypes.Pickem)
            .customRule((_, model) => {
                if (!model.responsiblePhonesFile.hasStrictValue || isNullOrUndefined(model.responsiblePhonesFile.strictValue)) {
                    return new StrictError('fileError', 'Необходимо загрузить файл');
                }
                return null;
            });
    }

    private comparer(date: dayjs.Dayjs): number {
        return isNullOrUndefined(date) ? Infinity : dayjs(date).unix();
    }

    private isAfterOrSame(first: dayjs.Dayjs, second: dayjs.Dayjs): boolean {
        return first.isAfter(second) || first.isSame(second);
    }
}
