import { WlCompleteTaskTypes, WlGameLootBoxTypes, WlGameLootBoxTypesExtensions } from 'src/app/modules/autogen/BattlePass';
import { WlCompleteTaskTypesExtensions } from '../../../../../../../autogen/Shared';

export class WlBattlePassGameLootBoxVariation {
    constructor(public readonly gameType: WlGameLootBoxTypes,
                public readonly taskType: WlCompleteTaskTypes) {}

    public getDescription(): string {
        return `${WlGameLootBoxTypesExtensions.format(this.gameType)} + ${WlCompleteTaskTypesExtensions.format(this.taskType)}`;
    }
}
