import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { formatDateTime, GaConfirmationService, GaMessagingService, GaStrictModelFactory, GaTableCellTemplate, GaTableData, isNullOrUndefined } from '@koddington/ga-common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TowersUserProfileSearchModel } from "./models/towers-user-profile-search-model";
import { SearchNavigationService } from "../../../shared/services/search-navigation.service";
import { filter, finalize, map, take } from 'rxjs/operators';
import {
    TowersService, WlCardPositionExtensions,
    WlDailyTasksUserTask, WlTowersUserAttempt,
    WlTowersUserDay,
    WlTowersUserProfile,
    WlTowersUserTicket,
    WlUserWithPromoForm
} from "../../../autogen/BattlePass";
import { RouterNavigationExtensions } from "../../../shared/extensions/navigation-extensions";
import { WlBlockUiService } from "../../../shared/services/wl-block-ui-service";

@UntilDestroy()
@Component({
    selector: 'app-towers-user-profile',
    templateUrl: './towers-user-profile.component.html',
    styleUrls: ['./towers-user-profile.component.scss'],
})
export class TowersUserProfileComponent implements OnInit {
    @ViewChild('showTask') showTask: TemplateRef<any>;
    protected viewModel: TowersUserProfileSearchModel = new TowersUserProfileSearchModel();
    protected loading = false;
    public result: WlTowersUserProfile = new WlTowersUserProfile();
    public dropTicketsTableData: GaTableData<WlTowersUserTicket>;
    public tasksTicketsTableData: GaTableData<WlTowersUserTicket>;
    public attemptsTableData: GaTableData<WlTowersUserAttempt>;
    public daysTableData: GaTableData<WlTowersUserDay>;
    private towerMaxLevel = 13;

    constructor(private readonly navigation: SearchNavigationService,
                private readonly activeRoute: ActivatedRoute,
                private readonly router: Router,
                private readonly confirmation: GaConfirmationService,
                private readonly messaging: GaMessagingService,
                private readonly blockUI: WlBlockUiService,
                private readonly service: TowersService) {
    }

    ngOnInit(): void {
        this.init();
    }

    public forceCompleteConfirmation(): void {
        this.confirmation
            .openDialog('Завершить уровень башни для пользователя?')
            .pipe(
                take(1),
                filter((res) => !!res)
            )
            .subscribe(() => this.forceComplete());
    }

    public typifyTask(rawTask: any): WlDailyTasksUserTask {
        return rawTask as WlDailyTasksUserTask;
    }

    public mapProgress(task: WlDailyTasksUserTask): string {
        return `${task.progress}/${task.target}`;
    }

    protected search(): void {
        const params: Params = {
            userId: this.viewModel.userId.strictValue,
            promoId: this.viewModel.promoId.strictValue,
        };
        RouterNavigationExtensions.navigateAndRefresh(this.router, [], {
            queryParams: params
        });
    }

    private init(): void {
        this.activeRoute.queryParams
            .pipe(
                map((value) => this.initModel(value)),
                filter(() => !this.isSearchForbidden),
                take(1)
            )
            .subscribe(() => this.load());
    }

    private load(): void {
        this.service
            .getProfile(this.getForm())
            .pipe(
                take(1),
                filter((res) => !this.messaging.tryShowError(res))
            )
            .subscribe((res) => {
                this.result = res?.result;
                this.mapToTable();
                this.loading = false;
            });
    }

    private initModel(params: Params): void {
        this.loading = true;
        this.viewModel.userId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
        this.viewModel.promoId.strictValue = !isNullOrUndefined(params['promoId']) ? Number(params['promoId']) : null;
    }

    private mapToTable(): void {
        this.prepareAttemptsToMap();
        this.prepareDropTicketsTableToMap();
        this.prepareTasksTicketsTableToMap();
        this.prepareTowerDaysToMap();
    }

    private prepareDropTicketsTableToMap(): void {
        const data = new GaTableData<WlTowersUserTicket>();
        data.addSimpleColumn((elem) => elem.rewardedDropAmount, {title: ' За сумму дропа', widthSize: 140})
            .addSimpleColumn((elem) => elem.id, {title: 'Id попытки', widthSize: 120})
            .addSimpleColumn((elem) => formatDateTime(elem.issuedAt), {title: 'Дата выдачи', widthSize: 180});

        this.dropTicketsTableData = data.setData(this.result.userDropTickets);
    }

    private prepareTasksTicketsTableToMap(): void {
        const data = new GaTableData<WlTowersUserTicket>();
        data.addRouterLinkColumn(
            (elem) => elem.dayId,
            (elem) => ['/menu/dailyTasks/days/update', elem.dayId],
            {title: 'За день', widthSize: 100}
        )
            .addSimpleColumn((elem) => elem.id, {title: 'Id попытки', widthSize: 120})
            .addSimpleColumn((elem) => formatDateTime(elem.issuedAt), {title: 'Дата выдачи', widthSize: 180});

        this.tasksTicketsTableData = data.setData(this.result.userTaskTickets);
    }

    private prepareAttemptsToMap(): void {
        const data = new GaTableData<WlTowersUserAttempt>();
        data.addSimpleColumn((elem) => elem.towerLevelOrder, {title: 'Номер башни', widthSize: 120})
            .addSimpleColumn((elem) => elem.id, {title: 'Id попытки', widthSize: 120})
            .addSimpleColumn((elem) => WlCardPositionExtensions.format(elem.card), {title: 'Выбранная карточка', widthSize: 180})
            .addSimpleColumn((elem) => formatDateTime(elem.usedAt), {title: 'Дата выбора', widthSize: 180})
            .addSimpleColumn((elem) => this.getOutcome(elem), {title: 'Исход', widthSize: 120});

        this.attemptsTableData = data.setData(this.result.userHistoryAttempts);
    }

    private getOutcome(attempt: WlTowersUserAttempt) : string {
        if (attempt.isWin)
            return "Выигрыш";

        if (attempt.isDraw)
            return "Ничья"

        return "Проигрыш";
    }

    private prepareTowerDaysToMap(): void {
        this.daysTableData = new GaTableData<WlTowersUserDay>()
            .addRouterLinkColumn(
                (elem) => elem.dayId,
                (elem) => ['/menu/dailyTasks/days/update', elem.dayId],
                {title: 'Id дня', widthSize: 80}
            )
            .addSimpleColumn((elem) => formatDateTime(elem?.startDate), {title: 'Дата старта дня', widthSize: 130})
            .addSimpleColumn((elem) => formatDateTime(elem?.endDate), {title: 'Дата окончания дня', widthSize: 150})
            .addTemplateColumn(
                new GaTableCellTemplate(this.showTask, (elem) => {
                    return {
                        task: elem.task
                    };
                }),
                {title: 'Задание', widthSize: 400}
            )
            .setData(this.result.userDays);
    }

    private forceComplete(): void {
        this.blockUI.start();
        this.service
            .forceComplete(this.getForm())
            .pipe(
                take(1),
                filter((res) => !this.messaging.tryShowError(res)),
                finalize(() => this.blockUI.stop())
            )
            .subscribe(() => {
                this.messaging.showMessage('Запрос на завершение уровня башни успешно отправлен');
                RouterNavigationExtensions.navigateAndRefresh(this.router, [], {queryParams: this.activeRoute.snapshot.queryParams});
            });
    }

    private getForm(): WlUserWithPromoForm {
        return GaStrictModelFactory.fromStrictToModel(WlUserWithPromoForm, this.viewModel);
    }

    get isSearchForbidden(): boolean {
        return isNullOrUndefined(this.viewModel.userId.strictValue) || isNullOrUndefined(this.viewModel.promoId.strictValue);
    }

    get isForceCompleteForbidden(): boolean {
        return this.result?.attemptsAvailable <= 0 || this.result?.userHistoryAttempts.filter(u => u.isWin === true).length >= this.towerMaxLevel;
    }
}
