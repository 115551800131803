import { Injectable } from '@angular/core';
import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { WlPromoTypes, WlPromoTypesExtensions } from '../../autogen/DailyTasks';

@Injectable({
    providedIn: 'root'
})
export class WlPromoTypesDropdownStrategy implements IDropdownStrategy<WlPromoTypes> {
    map: (model: WlPromoTypes) => DropdownItem<WlPromoTypes> = promoType => {
        return {
            entity: promoType,
            title: WlPromoTypesExtensions.format(promoType),
            id: Number(promoType)
        };
    };

    getEntities(): Observable<WlPromoTypes[]> {
        return of(EnumExtensions.toArray(WlPromoTypes));
    }
}
