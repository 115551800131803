import { AbstractModelValidator, AddErrorsToControlMode, isNullOrUndefined, StrictError, ValidationLevel } from '@koddington/ga-common';
import { DailyTasksDaysCrudViewModel } from '../models/daily-tasks-days-crud-view-model';
import { DailyTasksPlayersCardsSelectorValidator } from './daily-tasks-players-cards-selector-validator';
import { WlDailyTasksLimitsConsts } from '../../../consts/wl-daily-tasks-limits-consts';
import { OutcomesValidator } from './outcomes-validator';
import { WlDailyTaskStates, WlPromoTypes } from '../../../../autogen/DailyTasks';

export class DailyTasksDaysCrudValidator extends AbstractModelValidator<DailyTasksDaysCrudViewModel> {
    private _playerCardsSelectorValidator = new DailyTasksPlayersCardsSelectorValidator();
    private _needToValidateOutcome: boolean;
    private _outcomesSelectorValidator = new OutcomesValidator(() => this._needToValidateOutcome);

    constructor(private _hasPickemPropRule: (model: DailyTasksDaysCrudViewModel) => boolean) {

        super();

        this.ruleForControl(u => u.state)
            .customRule((field, model) => {
                let canChange: boolean;
                switch (model.promoType.strictValue) {
                    case WlPromoTypes.Pickem:
                        canChange = field <= WlDailyTaskStates.Active;
                        break;
                    default:
                        canChange = field <= WlDailyTaskStates.NotStarted;
                        break;
                }

                return canChange
                    ? null
                    : new StrictError('cantChangeDay', 'Нельзя менять день в данном статусе');
            });

        this.ruleForControl(u => u.name).requiredAndNotZero('Введите наименование');

        this.ruleForControl(u => u.promoType)
            .required('Тип акции обязятелен');

        this.ruleForControl((u) => u.startDate)
            .customRule((fieldValue, model) => {
                if (model?.endDate?.strictValue?.isBefore(fieldValue)) {
                    return new StrictError('DailyTasksDayError2', 'Дата начала не может быть больше даты окончания');
                }
                return null;
            });

        this.ruleForControl(u => u.startDate)
            .requiredAndNotZero('Дата начала обязательна');

        this.ruleForControl(u => u.endDate)
            .requiredAndNotZero('Дата окончания обязательна');

        this.ruleForControl((u) => u.description).notEmptyString();

        this.ruleForControlIf(
            u => u.crmTaskGroup,
            u => {
                if (u.isUnconditionalDay.strictValue)
                    return false;

                return u.promoType.strictValue === WlPromoTypes.Pickem
                    ? _hasPickemPropRule(u)
                    : !u.isOffPlayDay.strictValue;
            }
        )
            .required('Букмекерское задание обязательно');

        this.ruleForControl(u => u.crmTaskGroup)
            .customRule((field) => {
                return isNullOrUndefined(field?.levelId)
                    ? null
                    : new StrictError('taskUsedInLevel', 'Букмекерское задание используется в уровне боевого пропуска');
            });

        this.ruleForControl(u => u.crmTaskGroup)
            .customRule((field, model) => {
                if (model.isUnconditionalDay.strictValue && !isNullOrUndefined(field)) {
                    return new StrictError('DailyTaskDayCrmTaskGroupError', 'Безусловный день не может содержать задание');
                }
                return null;
            });

        this.addDailyTaskFreeBetRules();
        this.addPickemRules(_hasPickemPropRule);
    }

    private addDailyTaskFreeBetRules(): void {
        const needToValidate = (u: DailyTasksDaysCrudViewModel) => u.promoType.strictValue === WlPromoTypes.LootBox;

        this.ruleForControlIf(u => u.playerCards, needToValidate)
            .customRule((field) => {
                const errors = this._playerCardsSelectorValidator.validateArray(field);

                return errors.length > 0 ? new StrictError('DailyTasksDayError5', 'Значения карточек обязательны') : null;
            });

        this.ruleForControlIf(u => u.lootBox, needToValidate)
            .customRule((fieldValue, model) => {
                if (!model.isOffPlayDay.strictValue && isNullOrUndefined(fieldValue)) {
                    return new StrictError('DailyTasksDayError6', 'Лутбокс обязателен');
                }

                if (model.isUnconditionalDay.strictValue && isNullOrUndefined(fieldValue)) {
                    return new StrictError('DailyTaskDayLootBoxError', 'День с безусловным фрибетом должен содержать лутбокс');
                }

                if (model.isOffPlayDay.strictValue && model.crmTaskGroup?.strictValue?.id && isNullOrUndefined(fieldValue)) {
                    return new StrictError('DailyTasksDayError7', 'При выборе Бук. задания  - лутбокс обязателен');
                }
                return null;
            });

        this.ruleForControlIf(u => u.playerCards, needToValidate)
            .customRule((fieldValue) => {
                if (fieldValue.length < WlDailyTasksLimitsConsts.FreeBetPlayerCardsCount) {
                    return new StrictError('DailyTasksDayError8', 'Количество карточек должно быть равно '
                        + WlDailyTasksLimitsConsts.FreeBetPlayerCardsCount);
                }
                return null;
            });
    }

    private addPickemRules(hasPickemPropRule: (model: DailyTasksDaysCrudViewModel) => boolean): void {
        this.ruleForControlIf(u => u.pickemText, hasPickemPropRule)
            .notEmptyString('Текст прогноза обязателен');

        this.ruleForControlIf(u => u.forecastTypeImageUrl, hasPickemPropRule)
            .notEmptyString('Картинка типа прогноза обязательна');

        this.ruleForControlIf(u => u.usersForecastDeadline, hasPickemPropRule)
            .requiredAndNotZero('Дата окончания времени прогноза обязательна')
            .customRule((fieldValue, model) => {
                if (fieldValue < model.startDate.strictValue) {
                    return new StrictError('PickemError5', 'Время прогноза должно быть не меньше даты старта дня');
                }
                if (fieldValue > model.endDate.strictValue) {
                    return new StrictError('PickemError6', 'Время прогноза должно быть не больше даты окончания дня');
                }
                return null;
            });
    }

    public validate(model: DailyTasksDaysCrudViewModel, validationLevel?: ValidationLevel, addErrorsToControls?: AddErrorsToControlMode): StrictError[] {

        this._needToValidateOutcome = this._hasPickemPropRule(model);
        return [
            ...super.validate(model, validationLevel, addErrorsToControls),
            ...this._outcomesSelectorValidator.validateArray(model.outcomes.strictValue, validationLevel, addErrorsToControls)
        ];
    }
}

