import { Component, Inject, OnInit } from '@angular/core';
import { formatDateTime, GaTableData } from '@koddington/ga-common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WlTowersPlacesContainer } from '../../models/wl-towers-places.container';
import { WlUserPassedTowersModel } from '../../../autogen/BattlePass';

@Component({
  selector: 'app-wl-towers-places',
  templateUrl: './wl-towers-places.component.html'
})
export class WlTowersPlacesComponent implements OnInit {
    protected tableData: GaTableData<WlUserPassedTowersModel>;

    constructor(private readonly _dialogRef: MatDialogRef<WlTowersPlacesComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { container: WlTowersPlacesContainer, promoId: number }) {
    }

    ngOnInit() {
        this.mapTable(this.data.container.places);
    }

    protected close(): void {
        this._dialogRef.close();
    }

    private mapTable(places: WlUserPassedTowersModel[]): void {
        this.tableData = new GaTableData<WlUserPassedTowersModel>()
            .addSimpleColumn(u => u.userId, {title: 'Пользователь', widthSize: 100})
            .addSimpleColumn(u => u.userRewardId, {title: 'Id награды', widthSize: 200})
            .addSimpleColumn(u => formatDateTime(u.issuedFreebetAt), {title: 'Дата запроса на выдачу фрибета', widthSize: 400})
            .setData(places);
    }

    get title(): string {
        return `Топ игроков прошедших все урвони башни, промо №${this.data.promoId}`;
    }
}
