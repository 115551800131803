<app-wl-page-header header="Дни"></app-wl-page-header>
<div class="wl-template-row">
    <button class="wl-button-primary" (click)="add()">Добавить день</button>
</div>
<div class="wl-search-row">
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-left-4"
            [control]="viewSearchModel.id"
            [isSearch]="true"
            placeholder="Id дня"
            (searchMethod)="pageFiltered()"
            (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
    >
    </app-wl-strict-input-number>
    <app-wl-strict-input-text
            class="wl-default-size-search-autocomplete wl-margin-left-4"
            [control]="viewSearchModel.term"
            [isSearch]="true"
            placeholder="Название дня"
            (searchMethod)="pageFiltered()"
            (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
    >
    </app-wl-strict-input-text>
    <app-wl-dropdown
            class="wl-app-input-search-wrapper wl-input-marging wl-margin-left-4"
            isSearch="true"
            [strategy]="promoTypesStrategy"
            (userSelect)="pageFiltered()"
            [control]="viewSearchModel.dayType"
            [options]="{ placeholder: 'Выберите тип' }"
    >
    </app-wl-dropdown>
    <app-wl-dropdown
            class="wl-app-input-search-wrapper wl-input-marging wl-margin-left-4"
            isSearch="true"
            [strategy]="dailyTaskStatesStrategy"
            (userSelect)="pageFiltered()"
            [control]="viewSearchModel.state"
            [options]="{ placeholder: 'Выберите статус' }"
    >
    </app-wl-dropdown>
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-left-4"
            [control]="viewSearchModel.crmTaskGroupId"
            [isSearch]="true"
            placeholder="Id бук.задания"
            (searchMethod)="pageFiltered()"
            (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
    >
    </app-wl-strict-input-number>
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-left-4"
            [control]="viewSearchModel.lootBoxId"
            [isSearch]="true"
            placeholder="Id лутбокса"
            (searchMethod)="pageFiltered()"
            (keydown.enter)="$event.target.blur(); pageFiltered(); (false)"
    >
    </app-wl-strict-input-number>
</div>
<app-wl-loading *ngIf="this.loading"></app-wl-loading>
<ga-table *ngIf="!!tableData" [data]="tableData" [breakWordOnly]="true"></ga-table>
<div class="wl-paging-wrapper">
    <app-wl-paging *ngIf="result" [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></app-wl-paging>
</div>
