import { AbstractModelValidator, AddErrorsToControlMode, isNullOrUndefined, StrictError, StrictFormControl, ValidationLevel } from '@koddington/ga-common';
import { WlCompleteTaskTypes, WlFreeBetLootBoxListModel } from 'src/app/modules/autogen/BattlePass';
import { WlLevelFreeBetLootBoxViewModel } from '../../../../../models/wl-level-free-bet-loot-box-view-model';
import { WlBattlePassLootBoxesConsts } from './helpers/wl-battle-pass-loot-boxes-consts';

export class WlBattlePassLevelFreeBetLootBoxValidator extends AbstractModelValidator<WlLevelFreeBetLootBoxViewModel> {
    private _groupedFreeBetLootBoxes = new Map<WlCompleteTaskTypes, StrictFormControl<WlFreeBetLootBoxListModel>[]>();

    constructor() {
        super();

        this.ruleForControl(u => u.lootBox)
            .required('Фрибетный лутбокс обязателен')
            .customRule((field, model) => {
                if (isNullOrUndefined(model?.completedTaskType?.strictValue) || isNullOrUndefined(model?.lootBox?.strictValue)) {
                    return null;
                }

                const key = model.completedTaskType.strictValue;

                const lootDuplicates = this._groupedFreeBetLootBoxes
                                           .get(key)
                                           .filter(u => model?.lootBox?.strictValue !== u.strictValue)
                                           .map(u => u.strictValue.id);

                return lootDuplicates.length === 0
                    ? null
                    : new StrictError('freeBetLootBoxDuplicationError', `Указанный тип используется лутбоксами: ${lootDuplicates.join(', ')}`);
            });

        this.ruleForControl(u => u.completedTaskType)
            .required('Тип задания обязателен')
            .customRule(field => WlBattlePassLootBoxesConsts.availableCompleteTaskTypes.includes(field)
                ? null
                : new StrictError('gameLootBoxTaskTypeError', 'Указанный тип задания недоступен для уровней'));
    }

    public validateCustom(
        model: WlLevelFreeBetLootBoxViewModel[],
        knownLootBoxesPairs: Map<WlCompleteTaskTypes, StrictFormControl<WlFreeBetLootBoxListModel>[]>,
        validationLevel?: ValidationLevel,
        addErrorsToControls?: AddErrorsToControlMode): StrictError[] {
        this._groupedFreeBetLootBoxes = knownLootBoxesPairs;

        return super.validateArray(model, validationLevel, addErrorsToControls);
    }
}
