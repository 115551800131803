<app-wl-page-header header="Профиль пользователя"></app-wl-page-header>
<div class="wl-search-row">
    <app-wl-strict-input-number
            class="wl-app-input-search-wrapper wl-margin-right-8"
            isSearch="true"
            [control]="userId"
            [placeholder]="'Id пользователя'"
            [min]="0"
            (searchMethod)="search()"
    ></app-wl-strict-input-number>
</div>

<h4 *ngIf="!!result" class="wl-span-header-text">Статистика: </h4>
<div class="wl-userinfo-container" *ngIf="!!result">
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Id пользователя: </span>
        <span class="wl-userinfo-field-value">
            {{ result.userId }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Ставок: </span>
        <span class="wl-userinfo-field-value">
            {{ result.betCount }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Ставок лайв: </span>
        <span class="wl-userinfo-field-value">
            {{ result.liveCount }} ({{ result.liveRatio }}%)
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Ставок прематч: </span>
        <span class="wl-userinfo-field-value">
            {{ result.preMatchCount }} ({{ result.preMatchRatio }}%)
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Ординаров: </span>
        <span class="wl-userinfo-field-value">
            {{ result.ordinarCount }} ({{ result.ordinarRatio }}%)
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Экспрессов: </span>
        <span class="wl-userinfo-field-value">
            {{ result.expressCount }} ({{ result.expressRatio }}%)
        </span>
    </div>
</div>

<h4 *ngIf="result?.bestBet" class="wl-span-header-text">Лучшая ставка: </h4>
<div class="wl-userinfo-container" *ngIf="result?.bestBet">
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Id ставки: </span>
        <span class="wl-userinfo-field-value">
            {{ result.bestBet.betId }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Тип: </span>
        <span class="wl-userinfo-field-value">
            {{ result.bestBet.betType | wlBetTypesTransform }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Коэффициент: </span>
        <span class="wl-userinfo-field-value">
            {{ result.bestBet.betRate }}
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Вид спорта: </span>
        <span class="wl-userinfo-field-value">
            <span *ngIf="result.bestBet.sportId">{{ result.bestBet.sportId }}</span>
            <span *ngIf="!result.bestBet.sportId"> - </span>
        </span>
    </div>
    <div class="wl-user-info-row">
        <span class="wl-userinfo-field-label">Игроки: </span>
        <span class="wl-userinfo-field-value">
            <span *ngIf="result.bestBet.sportId">{{ result.bestBet.firstCompetitorName }}({{result.bestBet.firstCompetitorId}}) - {{ result.bestBet.secondCompetitorName }}({{result.bestBet.secondCompetitorId}})</span>
            <span *ngIf="!result.bestBet.sportId"> - </span>
        </span>
    </div>
</div>

<h4 *ngIf="result?.popularSports" class="wl-span-header-text">Любимые виды спорта: </h4>
<div *ngIf="result?.popularSports && result?.popularSports?.length < 1" class="wl-userinfo-container"> Отсутствуют любимые виды спорта</div>

<div *ngIf="result?.popularSports?.length > 0">
    <ga-table *ngIf="!!tableData" [data]="tableData" [breakWordOnly]="true"></ga-table>
</div>
