import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TrackerRoutes } from './app-tracker.module.routes';
import { GaCommonModule } from '@koddington/ga-common';
import { TrackerGamblerBindingProfileComponent } from './components/tracker-gambler-profile/tracker-gambler-binding-profile.component';
import { NgForOf, NgIf } from '@angular/common';
import { DailyTasksAreaCodegenAppModule } from '../autogen/DailyTasks';
import { NewYearProfileComponent } from './components/new-year-profile/new-year-profile.component';
import { BattlePassAreaCodegenAppModule } from '../autogen/BattlePass';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(TrackerRoutes),
        GaCommonModule,
        NgForOf,
        NgIf,
        DailyTasksAreaCodegenAppModule,
        BattlePassAreaCodegenAppModule,
    ],
  declarations: [
      TrackerGamblerBindingProfileComponent,
      NewYearProfileComponent
  ],
  exports: [
      NewYearProfileComponent,
      TrackerGamblerBindingProfileComponent
  ],
  providers: []
})
export class TrackerModule {
}
