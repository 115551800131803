import { Component, OnDestroy, OnInit } from '@angular/core';
import { formatDateTime, GaMessagingService, GaTableCellTemplate, GaTableData, isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';
import { SearchNavigationService } from '../../../shared/services/search-navigation.service';
import { ActivatedRoute, Params } from '@angular/router';
import { TopPopularSport, WlBetTypes, WlHappyNewYearProfile, WlHappyNewYearService } from '../../../autogen/BattlePass';
import { filter, map, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-new-year-profile',
  templateUrl: './new-year-profile.component.html',
  styleUrls: ['./new-year-profile.component.css']
})
export class NewYearProfileComponent implements OnInit, OnDestroy {
    protected loading = false;
    public userId: StrictFormControl<number> = new StrictFormControl<number>(null);
    public result: WlHappyNewYearProfile;

    public tableData: GaTableData<TopPopularSport>;

    public userManipulationsSource = new Subject<void>();

    constructor(private readonly messaging: GaMessagingService,
                private readonly service: WlHappyNewYearService,
                private readonly navigation: SearchNavigationService,
                private readonly activeRoute: ActivatedRoute) {
    }

    ngOnDestroy(): void {
    }
    ngOnInit(): void {
        this.activeRoute.queryParams
            .pipe(
                filter(u => this.userId.hasStrictValue),
                map((value) => this.initModel(value)),
                untilDestroyed(this)
            )
            .subscribe(() => this.load());
    }

    private load(): void {
        this.service
            .getProfile(this.userId.strictValue)
            .pipe(take(1),
                filter((res) => !this.messaging.tryShowError(res))
            )
            .subscribe((res) => {
                this.result = res?.result;
                this.mapToTable();
            });
    }

    private initModel(params: Params): void {
        this.loading = true;
        this.userId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
    }

    private mapToTable(): void {
        this.tableData = new GaTableData<TopPopularSport>()
            .addSimpleColumn((elem) => elem?.sportTitle, {title: 'Вид спорта', widthSize: 130})
            .addSimpleColumn((elem) => elem?.sportId, {title: 'Id вида спорта', widthSize: 150})
            .addSimpleColumn((elem) => elem?.sportPercentage, {title: 'Доля ставок', widthSize: 150})
            .setData(this.result.popularSports.sort((elem1, elem2) => elem1.position - elem2.position));
    }

    protected search(): void {
        const params: Params = {
            userId: this.userId.strictValue,
        };
        this.navigation.search(this.activeRoute, params);
    }
}
