import { Component, inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { GaMessagingService, GaStrictModelFactory, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, finalize, pairwise, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DailyTasksDaysCrudViewModel } from './models/daily-tasks-days-crud-view-model';
import { DailyTasksDaysCrudValidator } from './validator/daily-tasks-days-crud-validator';
import {
    DailyTasksDaysService,
    WlDailyTaskDayInfoForm,
    WlDailyTaskPlayerCardForm,
    WlDailyTaskStates,
    WlDtPromotionDay,
    WlDtPromotionDayPickemInfo,
    WlPickemInfoForm,
    WlPickemOutcomeForm,
    WlPromotionsDayCreateForm,
    WlPromotionsDayUpdateForm,
    WlPromoTypes
} from '../../../autogen/DailyTasks';
import { DailyTasksPlayersCardsSelectorViewModel } from './models/daily-tasks-players-cards-selector-view-model';
import { DailyTasksLootBoxAutocompleteStrategy } from '../../../battle-pass/strategies/daily-tasks-loot-box-autocomplete-strategy.service';
import dayjs from 'dayjs';
import { WlDailyTasksLimitsConsts } from '../../consts/wl-daily-tasks-limits-consts';
import { WlBattlePassCRMTaskScrollStrategy } from '../../../battle-pass/strategies/wl-battle-pass-crm-task-scroll-strategy';
import { WlBattlePassWrapperService } from '../../../battle-pass/services/wrapper-service/wl-battle-pass-wrapper-service';
import { WlPromoTypesDropdownStrategy } from '../../strategies/wl-promo-types.dropdown-strategy';
import gaMap = GaStrictModelFactory.gaMap;
import KeyMap = GaStrictModelFactory.KeyMap;
import { DailyTasksConsts } from '../../consts/daily-tasks.consts';


@UntilDestroy()
@Component({
    selector: 'app-daily-tasks-days-crud',
    templateUrl: './daily-tasks-days-crud.component.html',
    styleUrls: ['./daily-tasks-days-crud.component.scss'],
    providers: [DailyTasksLootBoxAutocompleteStrategy]
})
export class DailyTasksDaysCrudComponent implements OnInit {
  public loading: boolean;
    public viewModel: DailyTasksDaysCrudViewModel = new DailyTasksDaysCrudViewModel();
    public readonly userManipulationsSource = new Subject<void>();
    public errors: StrictError[] = [];

    protected readonly WlDailyTasksLimitsConsts = WlDailyTasksLimitsConsts;
    protected readonly crmTaskGroupStrategy = new WlBattlePassCRMTaskScrollStrategy(
        inject(WlBattlePassWrapperService),
        _ => { }
    );

    protected readonly WlPromoTypes = WlPromoTypes;
    protected readonly DailyTasksConsts = DailyTasksConsts;

    private readonly validator: DailyTasksDaysCrudValidator;

    private filledPickemDataRule = () => {
        if (this.promoType !== WlPromoTypes.Pickem) {
            return false;
        }

        return (this.viewModel.isUnconditionalDay.strictValue || this.viewModel.crmTaskGroup.hasStrictValue) ||
            this.viewModel.pickemText?.strictValue?.length > 0 ||
            this.viewModel.forecastTypeText?.strictValue?.length > 0 ||
            this.viewModel.forecastTypeImageUrl?.strictValue?.length > 0 ||
            this.viewModel.usersForecastDeadline.hasStrictValue ||
            this.viewModel.outcomes.strictValue.some(u =>
                u.name?.strictValue?.length > 0 ||
                u.description?.strictValue?.length > 0 ||
                u.imageUrl?.strictValue?.length > 0);
    }

    constructor(protected readonly lootBoxStrategy: DailyTasksLootBoxAutocompleteStrategy,
                protected readonly promoTypesStrategy: WlPromoTypesDropdownStrategy,
                private readonly _messaging: GaMessagingService,
                private readonly _router: Router,
                private readonly _route: ActivatedRoute,
                private readonly _titleService: Title,
                private readonly _service: DailyTasksDaysService,
    ) {
        this.validator = new DailyTasksDaysCrudValidator(this.filledPickemDataRule);
    }

    ngOnInit(): void {
        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];
      this.load();
        this.subscribeOnChanges();
    }

    public addPlayerCard(): void {
        const toAdd = new DailyTasksPlayersCardsSelectorViewModel();
        this.viewModel.playerCards.strictValue.push(toAdd);
        this.userManipulationsSource.next();
    }

    public deletePlayerCard(index: number): void {
        if (this.viewModel.playerCards.strictValue.length > 0) {
            this.viewModel.playerCards.strictValue =
                this.viewModel.playerCards.strictValue.filter((u, k) => k !== index);
            this.userManipulationsSource.next();
        }
    }

    public save(): void {
        this.loading = true;

        const promoType = this.promoType;
        if (promoType !== WlPromoTypes.LootBox)
            this.clearFreebetEveryDayProps();
        if (promoType !== WlPromoTypes.Pickem)
            this.clearPickemProps();

        if (this.isEdit) {
            this.updateTask();
        } else {
            this.addDay();
        }
    }

    public copyDayEndDate() {
      this.viewModel.usersForecastDeadline.strictValue = this.viewModel.endDate.strictValue;
      this.userManipulationsSource.next();
    }

    private clearFreebetEveryDayProps() {
      this.viewModel.lootBox.strictValue = null;
      this.viewModel.playerCards = null;
    }

    private clearPickemProps() {
      this.viewModel.usersForecastDeadline.strictValue = null;
      this.viewModel.outcomes = null;
      this.viewModel.pickemId = null;
      this.viewModel.pickemText = null;
      this.viewModel.forecastTypeImageUrl = null;
      this.viewModel.forecastTypeText = null;
    }

    private updateTask(): void {
        if (!this.isValid) {
            this.loading = false;
            return;
        }

        this.loading = true;
        const form = this.createUpdateForm();
        this._service.update(form).pipe(
            take(1),
            filter((value) => !this._messaging.tryShowError(value)),
            finalize(() => this.loading = false)
        ).subscribe(() => {
            this.showDaysList();
        });
    }

    private addDay(): void {
        if (!this.isValid) {
            this.loading = false;
            return;
        }

        this.loading = true;
        const form = this.createAddForm();
        this._service.add(form)
            .pipe(
                take(1),
                filter((value) => !this._messaging.tryShowError(value)),
                finalize(() => this.loading = false)
            ).subscribe(() => {
            this.showDaysList();
        });
    }

    private loadDay(): void {
        if (this.isEdit) {
            this._service.details(this.viewModel?.id?.value)
                .pipe(
                    take(1),
                    filter((value) => !this._messaging.tryShowError(value))
                ).subscribe((result) => {
                this.loadModel(result.result);
                this.setControlsState();
                this.loading = false;
            });
        } else {
            this.userManipulationsSource.next();
            this.loading = false;
        }
    }

    private subscribeOnChanges(): void {
        this.viewModel.promoType.valueChanges
            .pipe(
                pairwise(),
                filter(([prev, next]) => prev !== next),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.viewModel.isOffPlayDay.strictValue = false;
                this.viewModel.isUnconditionalDay.strictValue = false;
                this.userManipulationsSource.next();
            });

      this.viewModel.isOffPlayDay.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
          if (this.viewModel.isOffPlayDay.strictValue && this.promoType !== WlPromoTypes.Pickem) {
              this.viewModel.isUnconditionalDay.disable({emitEvent: false});
              return;
          }
          if (this.viewModel.state.strictValue === WlDailyTaskStates.NotStarted) {
              this.viewModel.isUnconditionalDay.enable({emitEvent: false});
          }
          if (!this.viewModel.isOffPlayDay.strictValue ) {
              this.viewModel.isUnconditionalDay.enable({emitEvent: false});
          }
        });

        this.viewModel.isUnconditionalDay.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.viewModel.isUnconditionalDay.strictValue && this.promoType !== WlPromoTypes.Pickem) {
                this.viewModel.isOffPlayDay.disable({emitEvent: false});
                return;
            }
            if (this.viewModel.state.strictValue === WlDailyTaskStates.NotStarted) {
                this.viewModel.isOffPlayDay.enable({emitEvent: false});
            }
            if (!this.viewModel.isUnconditionalDay.strictValue ) {
                this.viewModel.isOffPlayDay.enable({emitEvent: false});
            }
        });
    }


    private setControlsState(): void {
        if (this.viewModel.id.hasStrictValue) {
            this.viewModel.promoType.disable();
        }

        if (this.isNotStartedDay) {
            return;
        }

        this.viewModel.startDate.disable();
        this.viewModel.description.disable();
        this.viewModel.isOffPlayDay.disable();
        this.viewModel.isUnconditionalDay.disable();
        this.viewModel.name.disable();
        this.viewModel.endDate.disable();
        this.viewModel.crmTaskGroup.disable();
        this.viewModel.lootBox.disable();
        this.viewModel.playerCards.strictValue.forEach((card) => {
            card.playerCardUrl.disable();
            card.playerName.disable();
        });
        this.viewModel.usersForecastDeadline.disable();
        this.viewModel.pickemText.disable();
        this.viewModel.forecastTypeImageUrl.disable();
        this.viewModel.forecastTypeText.disable();
    }

    private loadModel(model: WlDtPromotionDay): void {
        const defaultKeyMappers: KeyMap[] = [
            {sourceKey: 'startDate', targetKey: 'startDate', newValue: dayjs(model.startDate)},
            {sourceKey: 'endDate', targetKey: 'endDate', newValue: dayjs(model.endDate)},
        ];

      switch ((model.promoType)) {
        case WlPromoTypes.LootBox: {
            GaStrictModelFactory.fromModelToStrict(this.viewModel, model, [
                {
                    sourceMapProperty: 'playerCards',
                    targetMapProperty: 'playerCards',
                    mapObject: gaMap(model.dailyTaskInfo.playerCards, (value) => {
                        const mapped = new DailyTasksPlayersCardsSelectorViewModel();
                        mapped.playerCardUrl.strictValue = value.playerCardUrl;
                        mapped.playerName.strictValue = value.playerName;
                        this.viewModel.playerCards.strictValue.push(mapped);
                        this.viewModel.lootBox.strictValue = model.dailyTaskInfo.lootBox;
                        return mapped;
                    }),
                },
            ], defaultKeyMappers);
            break;
        }
        case WlPromoTypes.Pickem: {
            if (model.pickemInfo === null) {
                model.pickemInfo = new WlDtPromotionDayPickemInfo();
                model.pickemInfo.outcomes = [];
            }
            GaStrictModelFactory.fromModelToStrict(this.viewModel, model, [
                {
                    sourceMapProperty: 'outcomes',
                    targetMapProperty: 'outcomes',
                    mapObject: gaMap(model.pickemInfo.outcomes, (value) => {
                        const outcome = this.viewModel.outcomes.strictValue.find((u) => u.order.strictValue === value.order);
                        GaStrictModelFactory.fromModelToStrict(outcome, value);
                        this.viewModel.pickemId.strictValue = model.pickemInfo.id;
                        this.viewModel.usersForecastDeadline.strictValue = !isNullOrUndefined(model.pickemInfo.usersForecastDeadline)
                            ? dayjs(model.pickemInfo.usersForecastDeadline)
                            : null;

                        this.viewModel.pickemText.strictValue = model.pickemInfo.pickemText;
                        this.viewModel.forecastTypeImageUrl.strictValue = model.pickemInfo.forecastTypeImageUrl;
                        this.viewModel.forecastTypeText.strictValue = model.pickemInfo.forecastTypeText;
                        return outcome;
                    }),

                },
            ], defaultKeyMappers);
            break;
        }
        case WlPromoTypes.Towers:
        case WlPromoTypes.WinterLeague: {
            GaStrictModelFactory.fromModelToStrict(this.viewModel, model, null, defaultKeyMappers);
            break;
        }
      }

        this.userManipulationsSource.next();
    }

    private createAddForm(): WlPromotionsDayCreateForm {
        const form = GaStrictModelFactory.fromStrictToModel(WlPromotionsDayCreateForm, this.viewModel, null, [
            {
                sourceKey: 'dailyTaskDayInfo',
                targetKey: 'lootBoxId',
                newValue: this.viewModel.lootBox.strictValue?.id
            },
            {
                sourceKey: 'crmTaskGroup',
                targetKey: 'crmTaskGroupId',
                newValue: this.viewModel.crmTaskGroup.strictValue?.id
            }
        ]);

        switch (form.promoType) {
            case WlPromoTypes.Pickem:
                if (!this.filledPickemDataRule()) {
                    break;
                }
                form.pickemInfo = new WlPickemInfoForm();
                form.pickemInfo.outcomes = this.mapOutcomes();
                form.pickemInfo.usersForecastDeadline = this.viewModel.usersForecastDeadline.strictValue;
                form.pickemInfo.id = this.viewModel.pickemId.strictValue;
                form.pickemInfo.pickemText = this.viewModel.pickemText.strictValue;
                form.pickemInfo.forecastTypeImageUrl = this.viewModel.forecastTypeImageUrl.strictValue;
                form.pickemInfo.forecastTypeText = this.viewModel.forecastTypeText.strictValue;
                break;
            case WlPromoTypes.LootBox:
                form.dailyTaskDayInfo = new WlDailyTaskDayInfoForm();
                form.dailyTaskDayInfo.cardsForm = this.mapPlayerCardItems();
                form.dailyTaskDayInfo.lootBoxId = this?.viewModel?.lootBox?.strictValue?.id;
                break;
            case WlPromoTypes.Towers:
            case WlPromoTypes.WinterLeague:
                break;
        }
        form.isUnconditionalDay = this.viewModel?.isUnconditionalDay?.strictValue;
        form.isOffPlayDay = this.viewModel?.isOffPlayDay?.strictValue;


        return form;
    }

    private createUpdateForm(): WlPromotionsDayUpdateForm {
        const form = new WlPromotionsDayUpdateForm(this.createAddForm());
        form.id = this.viewModel.id.strictValue;

        return form;
    }

    private showDaysList(): void {
        this._router.navigate(['/menu/dailyTasks/days']);
    }

    private load(): void {
        this.loading = true;

        this.viewModel.id.strictValue = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];

        this.userManipulationsSource.pipe(untilDestroyed(this)).subscribe(() => {
            this.errors = this.validator.validate(this.viewModel);
        });
        this.loadDay();
        this._titleService.setTitle('Создание / Редактирование игрового дня');
    }

    private mapPlayerCardItems(): WlDailyTaskPlayerCardForm[] {
        return this.viewModel.playerCards.strictValue.map((val) => {
            const playerCardForm = new WlDailyTaskPlayerCardForm();

            playerCardForm.playerName = val.playerName.strictValue;
            playerCardForm.playerCardUrl = val.playerCardUrl.strictValue;

            return playerCardForm;
        });
    }

  private mapOutcomes(): WlPickemOutcomeForm[] {

    return this.viewModel.outcomes.strictValue.map((val) => {
      const outcomeForm = new WlPickemOutcomeForm();
      outcomeForm.id = val.id.strictValue;
      outcomeForm.name = val.name.strictValue;
      outcomeForm.description = val.description.strictValue;
      outcomeForm.imageUrl = val.imageUrl.strictValue;
        outcomeForm.order = val.order.strictValue;

      if (outcomeForm.name === null
      && outcomeForm.description === null
      && outcomeForm.imageUrl === null) {
          return  null;
      }
      return outcomeForm;
        });
    }

    get canCopyEndDate(): boolean {
        return this.isNotStartedDay && this.viewModel.endDate.hasStrictValue;
    }

    get canUpdate(): boolean {
        return this.errors.length === 0;
    }

    get canAddMore(): boolean {
        return this.viewModel.playerCards.strictValue.length < WlDailyTasksLimitsConsts.FreeBetPlayerCardsCount;
    }

    get purpose(): string {
        if (this.isEdit) {
            return `Редактирование игрового дня № ${this.viewModel.id.strictValue}`;
        }
        return 'Создание игрового дня';
    }

    get saveButtonText(): string {
        if (this.isEdit) {
            return 'Обновить';
        }
        return 'Сохранить';
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }

    get isEdit(): boolean {
        return this.viewModel.id.hasStrictValue;
    }

    get isPlayerCardsConflict(): boolean {
        return this.viewModel.playerCards.strictValue.length < WlDailyTasksLimitsConsts.FreeBetPlayerCardsCount;
    }

    get routerLinkForCrmTask(): any[] | null {
        return this.viewModel?.crmTaskGroup?.hasStrictValue
            ? ['/menu/battlePass/task/edit/', this.viewModel?.crmTaskGroup?.strictValue.id]
            : null;
    }

    get routerLinkForLootBox(): any[] | null {
        return this.viewModel?.lootBox?.hasStrictValue
            ? ['/menu/dailyTasks/loot-boxes/update/', this.viewModel?.lootBox?.strictValue.id]
            : null;
    }

    get isNotStartedDay() {
        return !this.viewModel.id.hasStrictValue || this.viewModel.state.strictValue === WlDailyTaskStates.NotStarted;
    }

    get promoType(): WlPromoTypes {
        return this.viewModel?.promoType.strictValue;
    }
}
