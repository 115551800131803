import { WlPromoTypes } from '../../autogen/DailyTasks';

export namespace DailyTasksConsts {
    export const mobileDataRequiredPromoTypes: WlPromoTypes[] = [WlPromoTypes.LootBox, WlPromoTypes.Pickem, WlPromoTypes.Towers];
    export const canHavePromoTaskPromoTypes: WlPromoTypes[] = [WlPromoTypes.Pickem, WlPromoTypes.Towers];
    export const promoTaskRequiredPromoTypes: WlPromoTypes[] = [WlPromoTypes.Towers];

    export const canHaveOffPlayDayPromoTypes: WlPromoTypes[] = [WlPromoTypes.LootBox, WlPromoTypes.Pickem];
    export const canHaveUnconditionalDayPromoTypes: WlPromoTypes[] = [WlPromoTypes.LootBox, WlPromoTypes.Pickem, WlPromoTypes.Towers];
}
