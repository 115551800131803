import { WlSeasonViewModel } from '../view-models/wl-season-view-model';
import * as dayjs from 'dayjs';
import { AbstractModelValidator, isNullOrUndefined, StrictError } from '@koddington/ga-common';
import { WlBattlePassStates, WlSeasonLevelModel } from '../../../../autogen/BattlePass';
export class WlBattlePassSeasonCrudValidator extends AbstractModelValidator<WlSeasonViewModel> {

    private readonly MAX_GAMBLER_LEVELS_COUNT: number = 1;

    constructor(minLevelsCount: number, maxLevelsCount: number) {
        super();

        this.ruleForControl((u) => u.name).notEmptyString();
        this.ruleForControl((u) => u.description).notEmptyString('Добавьте описание сезона');
        this.ruleForControl((u) => u.imageUrl).notEmptyString('Добавьте ссылку на изображение');
        this.ruleForControl((u) => u.startDate).required('Дата начала сезона обязательна');
        this.ruleForControl((u) => u.endDate).required('Дата технического расчёта обязательна');
        this.ruleForControl((u) => u.endDate).customRule((fieldValue, model) => {
            if (model.startDate.hasStrictValue && model.endDate.hasStrictValue && fieldValue < model.startDate.strictValue) {
                return new StrictError('endDateError', 'Дата технического расчёта должна быть больше даты начала');
            }

            return null;
        });
        this.ruleForControl((u) => u.endDate)
            .customRule((_, model) => {
                if (!model.endDate.hasStrictValue) {
                    return null;
                }

                const isLevelStartDateOut = model.levels.strictValue.findIndex((level) => {
                    return dayjs(level.startDate).unix() > model.endDate.strictValue.unix();
                });
                if (isLevelStartDateOut !== -1)
                    return new StrictError(
                        'endDateError',
                        'В сезоне есть уровни с датой старта позже, чем дата технического расчёта сезона.'
                    );

                return null;
            });
        this.ruleForControl((u) => u.startDate).customRule((fieldValue, model) => {
            if (model.state.hasStrictValue && model.state.strictValue !== WlBattlePassStates.NotStarted)
                return null;
            if (model.startDate.hasStrictValue && model.startDate.strictValue.unix() < dayjs().unix()) {
                return new StrictError(
                    'startDateError',
                    'Дата старта сезона не может быть меньше текущего времени, изменения не будут сохранены'
                );
            }

            return null;
        });
        this.ruleForControl((u) => u.payoutDate).customRule((fieldValue, model) => {
            if (model.endDate.hasStrictValue && model.payoutDate.hasStrictValue && fieldValue.unix() < model.endDate.strictValue.unix()) {
                return new StrictError('payoutDateError', 'Дата фактического расчёта должна быть больше даты технического расчёта');
            }

            return null;
        });
        this.ruleForControl((u) => u.levelsCount)
            .required('Укажите количество уровней сезона')
            .min(minLevelsCount, `Количество уровней не должно быть меньше  ${minLevelsCount}`)
            .max(maxLevelsCount, `Количество уровней сезона не должно превышать ${maxLevelsCount}`);
        this.ruleForControl((u) => u.levels)
            .customRule((fieldValue) => this.levelsUniqueCrmTaskRule(fieldValue))
            .customRule((fieldValue) => this.levelsUniqueGameTaskRule(fieldValue))
            .customRule((fieldValue, model) => {
                if (fieldValue.length !== model.levelsCount.strictValue) {
                    return new StrictError('levelsError', 'Число добавленных уровней не соответствует указанному');
                }

                return null;
            })
            .customRule(field => {
                return field.filter(u => u.isGamblerLevel).length > this.MAX_GAMBLER_LEVELS_COUNT
                    ? new StrictError('gamblerLevelsCountError', `Количество уровней лудки превышает ${this.MAX_GAMBLER_LEVELS_COUNT}`)
                    : null;
            })
            .customRule(field => this.validateGamblerLevelOrders(field))
            .customRule((field, model) => {
                const firstLevel = field.find(u => u.order === 1);
                if (isNullOrUndefined(firstLevel))
                    return null;

                if (isNullOrUndefined(firstLevel.startDate))
                    return new StrictError('firstLevelStartDateError', 'У первого уровня должна быть дата старта');

                if (typeof firstLevel.startDate === 'string') // dates mapping from API workaround
                    firstLevel.startDate = dayjs(firstLevel.startDate);

                return !model.startDate.hasStrictValue || firstLevel.startDate.isSame(model.startDate.strictValue)
                    ? null
                    : new StrictError('firstLevelStartDateError', 'Дата старта первого уровня должна совпадать с датой старта сезона');
            });
    }

    private levelsUniqueCrmTaskRule(fieldValue: WlSeasonLevelModel[]): StrictError | null {
        const crmTaskGroupIds: number[] = [];

        fieldValue.forEach((val) => {
            val.crmTaskGroups.forEach((task) => {
                if (!isNullOrUndefined(task.taskGroup)) {
                    crmTaskGroupIds.push(task.taskGroup.id);
                }
            });
        });

        if (crmTaskGroupIds.length !== Array.from(new Set(crmTaskGroupIds)).length) {
            return new StrictError('levelsError', 'Букмекерские задания должны быть уникальными');
        }

        return null;
    }

    private levelsUniqueGameTaskRule(fieldValue: WlSeasonLevelModel[]): StrictError | null {
        const gameTaskIds: number[] = [];

        fieldValue.forEach((val) => {
            val.gameTasks.forEach((task) => {
                if (!isNullOrUndefined(task.task)) {
                    gameTaskIds.push(task.task.id);
                }
            });
        });

        if (gameTaskIds.length !== Array.from(new Set(gameTaskIds)).length) {
            return new StrictError('levelsError', 'Игровые задания должны быть уникальными');
        }

        return null;
    }

    private sortLevelsOrdersFn(leftOrder: number, rightOrder: number, ascending: boolean) {
        const orderDiff = leftOrder - rightOrder;
        if (ascending === true) {
            return orderDiff;
        }

        return orderDiff * -1;
    }

    private validateGamblerLevelOrders(field: WlSeasonLevelModel[]) {
        const gamblerLevelOrdersAsc = field.filter(u => u.isGamblerLevel)
            .map(u => u.order)
            .sort((a, b) => this.sortLevelsOrdersFn(a, b, true));

        if (gamblerLevelOrdersAsc.length === 0)
            return null;

        const minGamblerLevelOrder = gamblerLevelOrdersAsc[0];

        const incorrectDefaultLevelOrders = field.filter(u => !u.isGamblerLevel && u.order > minGamblerLevelOrder)
            .map(u => u.order)
            .sort((a, b) => this.sortLevelsOrdersFn(a, b, true));

        if (incorrectDefaultLevelOrders.length === 0)
            return null;

        return new StrictError(
            'gamblerLevelOrdersError',
            `Уровни ${incorrectDefaultLevelOrders.join(', ')} имеют порядок выше, чем уровень лудки ${minGamblerLevelOrder}`
        );
    }
}
