import {Component, EventEmitter, Input, Output} from '@angular/core';
import { isNullOrUndefined } from '@koddington/ga-common';
import {Dayjs} from 'dayjs';
import { WlCrmTaskGroupLiteModel, WlGameTaskLiteModel, WlLevelFreeBetLootBoxModel, WlLevelGameLootBoxModel, WlSeasonLevelModel } from '../../../../../autogen/BattlePass';

@Component({
    selector: 'app-wl-battle-pass-level-view',
    templateUrl: './wl-battle-pass-level-view.component.html',
    styleUrls: ['./wl-battle-pass-level-view.component.scss']
})
export class WlBattlePassLevelViewComponent {

    @Input()
    public level: WlSeasonLevelModel;
    @Input()
    public canDelete: boolean;
    @Output()
    public editEmitter = new EventEmitter<void>();
    @Output()
    public delEmitter = new EventEmitter<void>();

    constructor() {
    }

    public edit(): void {
        this.editEmitter.emit();
    }

    public delete(): void {
        this.delEmitter.emit();
    }

    public crmTaskEmpty(task: WlCrmTaskGroupLiteModel): boolean {
        if (isNullOrUndefined(task.order)) {
            return true;
        }

        if (isNullOrUndefined(task.taskGroup)) {
            return true;
        }

        return false;
    }

    public gameTaskEmpty(task: WlGameTaskLiteModel): boolean {
        if (isNullOrUndefined(task.order)) {
            return true;
        }

        if (isNullOrUndefined(task.task)) {
            return true;
        }

        return false;
    }

    get levelName(): string {
        return this.level.name;
    }

    get hasTemplate(): boolean {
        return !isNullOrUndefined(this.level.template);
    }

    get templateName(): string {
        return this.level.template?.name;
    }

    get templateId(): number {
        return this.level.template?.id;
    }

    get startDate(): Dayjs {
        return !isNullOrUndefined(this.level.startDate) ? this.level.startDate : null;
    }

    public get hasCrmTaskGroups(): boolean {
        return this.crmTaskGroups.length > 0;
    }

    public get crmTaskGroups(): WlCrmTaskGroupLiteModel[] {
        const tasks = this.level?.crmTaskGroups?.filter(u => !isNullOrUndefined(u.taskGroup));
        return tasks ?? [];
    }

    public get hasGameTasks(): boolean {
        return this.gameTasks.length > 0;
    }

    public get gameTasks(): WlGameTaskLiteModel[] {
        const tasks = this.level?.gameTasks?.filter(u => !isNullOrUndefined(u.task));
        return tasks ?? [];
    }

    public get hasGameLootBoxes(): boolean {
        return this.gameLootBoxes.length > 0;
    }

    public get gameLootBoxes(): WlLevelGameLootBoxModel[] {
        const gameLootBoxes = this.level?.gameLootBoxes?.filter(u => !!u.id);
        return gameLootBoxes ?? [];
    }

    public get hasFreeBetLootBoxes(): boolean {
        return this.freeBetLootBoxes.length > 0;
    }

    public get freeBetLootBoxes(): WlLevelFreeBetLootBoxModel[] {
        const freeBetLootBoxes = this.level?.freeBetLootBoxes?.filter(u => !!u.id);
        return freeBetLootBoxes ?? [];
    }
}
