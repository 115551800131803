import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BattlePassAreaCodegenAppModule } from '../autogen/BattlePass';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GaCommonModule } from '@koddington/ga-common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { DailyTasksRoutes } from './daily-tasks.routes';
import { WlPromotionsListComponent } from './components/wl-promotions-list/wl-promotions-list.component';
import { WlPromotionsCrudComponent } from './components/wl-promotions-crud/wl-promotions-crud.component';
import { DailyTasksLootBoxesListComponent } from './components/loot-boxes/daily-tasks-loot-boxes-list/daily-tasks-loot-boxes-list.component';
import { DailyTasksLootBoxesCrudComponent } from './components/loot-boxes/daily-tasks-loot-boxes-crud/daily-tasks-loot-boxes-crud.component';
import { DailyTasksAreaCodegenAppModule } from '../autogen/DailyTasks';
import { WlBattlePassModule } from '../battle-pass/wl-battle-pass.module';
import { DailyTasksDaysListComponent } from './components/daily-tasks-days-list/daily-tasks-days-list.component';
import { DailyTasksDaysCrudComponent } from './components/daily-tasks-days-crud/daily-tasks-days-crud.component';
import { DailyTaskUserInfoComponent } from './components/daily-task-user-info/daily-task-user-info.component';
import { WlDailyTaskFilePickerComponent } from './components/wl-promotions-crud/wl-daily-task-file-picker/wl-daily-task-file-picker.component';
import { WlPromotionsPlacesComponent } from './components/wl-promotions-places/wl-promotions-places.component';
import { TowersUserProfileComponent } from './components/towers-user-profile/towers-user-profile.component';
import { SharedAreaCodegenAppModule } from '../autogen/Shared';
import { WlTowersPlacesComponent } from './components/wl-towers-places/wl-towers-places.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        SharedModule.forRoot(),
        RouterModule.forChild(DailyTasksRoutes),
        FormsModule,
        BattlePassAreaCodegenAppModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatCardModule,
        MatSliderModule,
        MatSnackBarModule,
        GaCommonModule,
        MatAutocompleteModule,
        MatDialogModule,
        DailyTasksAreaCodegenAppModule,
        WlBattlePassModule,
        SharedAreaCodegenAppModule
    ],
    declarations: [
        WlPromotionsListComponent,
        WlPromotionsCrudComponent,
        DailyTasksLootBoxesListComponent,
        DailyTasksLootBoxesCrudComponent,
        DailyTasksDaysListComponent,
        DailyTasksDaysCrudComponent,
        DailyTaskUserInfoComponent,
        WlDailyTaskFilePickerComponent,
        WlPromotionsPlacesComponent,
        TowersUserProfileComponent,
        WlTowersPlacesComponent,
    ],
    exports: [
        WlPromotionsListComponent,
        WlPromotionsCrudComponent,
        DailyTasksDaysListComponent,
        DailyTasksDaysCrudComponent,
        WlDailyTaskFilePickerComponent,
        TowersUserProfileComponent
    ],
    providers: [],
})
export class DailyTasksModule {
}
