import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { WlBattlePassStates, WlCrmTaskGroupSearchModel, WlPromoTypes } from '../../../../autogen/DailyTasks';
import { WlDailyTasksDaysSelectorViewModel } from './wl-daily-tasks-days-selector-view-model';

export class WlPromotionViewModel {
    public id: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public freeBetId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public daysCount: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public startDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public technicalEndDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs({disabled: true});
    public endDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public state: StrictFormControl<WlBattlePassStates> = StrictFormControlBuilder.buildNullNumber();
    public days: StrictFormControl<WlDailyTasksDaysSelectorViewModel[]> = new StrictFormControl<WlDailyTasksDaysSelectorViewModel[]>([]);
    public headerColor: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public headerImageUrl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public textColor: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public isLightTheme: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public responsiblePhonesFile: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public usersListFileName: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public fileLoaded: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public type: StrictFormControl<WlPromoTypes> = StrictFormControlBuilder.buildNullNumber();
    public task: StrictFormControl<WlCrmTaskGroupSearchModel> = StrictFormControlBuilder.buildNullObject(WlCrmTaskGroupSearchModel);
}
