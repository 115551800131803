<div class="wl-crm-task-container">
    <app-wl-page-header header="{{purpose}}"></app-wl-page-header>
    <app-wl-loading *ngIf="loading"></app-wl-loading>
    <div class="wl-crm-task-row">
        <button class="wl-button-primary wl-task-button" [disabled]="!isValid"
                (click)="save()">{{ saveButtonText }}
        </button>
        <button class="wl-button-primary wl-task-button" [disabled]="!canBeCloned"
                (click)="clone()">Копировать
        </button>
    </div>
    <app-wl-strict-input-text class="wl-crm-task-row"
                              [control]="viewModel.name"
                              title="Название"
                              placeholder="Введите название задания"
                              (valueChange)="emmitMethod()"
                              (searchMethod)="emmitMethod()"
    >
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-crm-task-row"
                              [control]="viewModel.description"
                              title="Описание"
                              placeholder="Введите описание задания"
                              (valueChange)="emmitMethod()"
                              (searchMethod)="emmitMethod()"
                              [className]="'wl-battle-pass-task-name'"
    >
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-crm-task-row"
                              [control]="viewModel.bullets"
                              title="Буллиты"
                              placeholder="Дополнительные условия выполнения задания"
                              (valueChange)="emmitMethod()"
                              (searchMethod)="emmitMethod()"
                              [className]="'wl-battle-pass-task-name'"
    >
    </app-wl-strict-input-text>
    <app-wl-strict-input-boolean class="wl-crm-task-row"
                                 label="Пройдено автоматически"
                                 (userSelect)="userManipulationsSource.next()"
                                 [control]="viewModel.forceComplete"
    >
    </app-wl-strict-input-boolean>
    <app-wl-strict-input-text class="wl-crm-task-row"
                              title="Deeplink"
                              (valueChange)="emmitMethod()"
                              (searchMethod)="emmitMethod()"
                              [control]="viewModel.deeplink"
    >
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-crm-task-row"
                              title="Картинка"
                              (valueChange)="emmitMethod()"
                              (searchMethod)="emmitMethod()"
                              [control]="viewModel.imageUrl"
    >
    </app-wl-strict-input-text>
    <app-wl-strict-input-text class="wl-crm-task-row"
                              title="Картинка для Web"
                              (valueChange)="emmitMethod()"
                              (searchMethod)="emmitMethod()"
                              [control]="viewModel.imageWebUrl"
    >
    </app-wl-strict-input-text>
    <app-strict-push-template-autocomplete class="wl-crm-task-row wl-app-search-autocomplete-wrapper"
                                           textlabel="Push шаблон рассылки о выполнении задания"
                                           [control]="viewModel.template"
                                           (userSelect)="emmitMethod()"
                                           [isSearch]="true"
    ></app-strict-push-template-autocomplete>
    <app-strict-throttling-autocomplete class="wl-crm-task-row"
                                        textLabel="Троттлинг для рассылки"
                                        (userSelect)="emmitMethod()"
                                        [control]="viewModel.throttling">
    </app-strict-throttling-autocomplete>
    <app-wl-strict-input-number class="wl-crm-task-row"
                                title="Целевой показатель"
                                min="0"
                                placeholder=""
                                (valueChange)="userManipulationsSource.next()"
                                [control]="viewModel.targetIndicator"
    >
    </app-wl-strict-input-number>
    <app-wl-strict-input-text class="wl-crm-task-row"
                              [control]="viewModel.targetIndicatorType"
                              placeholder="Укажите тип целевого показателя"
                              title="Единица измерения целевого показателя"
                              (valueChange)="emmitMethod()"
                              (searchMethod)="emmitMethod()"
    ></app-wl-strict-input-text>
    <app-wl-radio-buttons class="wl-crm-task-row"
                          (userSelect)="userManipulationsSource.next()"
                          [control]="switcherEvents">
    </app-wl-radio-buttons>
    <ng-container *ngIf="isGrouped">
        <app-wl-dropdown class="wl-crm-task-row"
                         [strategy]="groupingTypeStrategy"
                         [options]="{placeholder: 'Выберите параметр группировки',title:'Параметр группировки '}"
                         (userSelect)="userManipulationsSource.next()"
                         [control]="viewModel.groupViewModel.groupType">
        </app-wl-dropdown>
        <app-wl-strict-input-number class="wl-crm-task-row"
                                    title="Число элементов группировки"
                                    min="1"
                                    (valueChange)="emmitMethod()"
                                    [control]="viewModel.groupViewModel.groupsCount">
        </app-wl-strict-input-number>
        <app-wl-strict-input-number *ngIf="isByCount"
                                    class="wl-crm-task-row"
                                    title="Событий в группе"
                                    min="1"
                                    (valueChange)="emmitMethod()"
                                    [control]="viewModel.groupViewModel.eventsCountInGroup">
        </app-wl-strict-input-number>
        <app-wl-strict-input-number *ngIf="isBySum"
                                    class="wl-crm-task-row"
                                    title="Сумма ставок в группе"
                                    min="1"
                                    (valueChange)="emmitMethod()"
                                    [control]="viewModel.groupViewModel.eventsSumInGroup">
        </app-wl-strict-input-number>
    </ng-container>
    <app-wl-battle-pass-task-express *ngIf="isCollectInExpress"
                                     class="wl-crm-task-row"
                                     (change)="userManipulationsSource.next()"
                                     [viewModel]="viewModel">
    </app-wl-battle-pass-task-express>
    <app-wl-strict-input-boolean *ngIf="!isOtherType"
                                 class="wl-crm-task-row"
                                 label="Ограничить выполнение по времени"
                                 (userSelect)="userManipulationsSource.next()"
                                 [control]="viewModel.limitProgressByDays"
    >
    </app-wl-strict-input-boolean>
    <app-wl-strict-input-number *ngIf="isProgressLimitedByDays"
                                class="wl-crm-task-row"
                                title="Количество суток"
                                min="1"
                                max="999"
                                (valueChange)="emmitMethod()"
                                [control]="viewModel.progressIntervalSize">
    </app-wl-strict-input-number>
    <app-wl-dropdown class="wl-crm-task-row"
                     [strategy]="taskTypeStrategy"
                     [options]="{placeholder: 'Выберите тип задания',title:'Тип букмекерского задания '}"
                     (userSelect)="userManipulationsSource.next()"
                     [control]="viewModel.taskType">
    </app-wl-dropdown>
    <app-wl-dropdown *ngIf="!isOtherType"
                     class="wl-crm-task-row"
                     [strategy]="targetTypeStrategy"
                     [options]="{placeholder: 'Выберите цель задания',title:'Цель задания '}"
                     (userSelect)="userManipulationsSource.next()"
                     [control]="viewModel.targetType">
    </app-wl-dropdown>
    <ng-container *ngIf="isTasksExist && !isOtherType">
        <wl-crm-task-crud-item *ngFor="let task of viewModel.tasks.strictValue; let i = index"
                                      class="wl-crm-task-row"
                                      (change)="userManipulationsSource.next()"
                                      (delEmitter)="deleteTask(i)"
                                      (addEmitter)="addTask()"
                                      [isDeleteButtonActive]="canDeleteTask"
                                      [taskItem]="task"
                                      [taskTypeControl]="viewModel.taskType"
                                      [taskType]="viewModel.taskType.strictValue"
                                      [targetType]="viewModel.targetType.strictValue"
                                      [collectInExpress]="viewModel.switcherEvents.isCollectInExpress"
                                      [isGrouped]="viewModel.switcherEvents.isGrouped.strictValue"
                                      [isRecurrent]="viewModel.switcherEvents.isRecurrent.strictValue"
                                      [isDisabled]="isStarted">
        </wl-crm-task-crud-item>
    </ng-container>
</div>
